import { storyblokEditable } from "@storyblok/react";
import AccountAddressCMS from "../../cms/user-area/AccountAddressCMS";

const StoryblokAccountAddress = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <AccountAddressCMS flex={blok.flex} gap={blok.gap} />
    </div>
  );
};

export default StoryblokAccountAddress;
