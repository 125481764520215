import { storyblokEditable } from "@storyblok/react";
import React from "react";
import { Product } from "@precomposer/data";
import ProductDescription from "../../cms/product-detail/ProductDescription";

const StoryblokProductDescription = ({ blok, ...restProps }) => {
  const product: Product = restProps.product;

  return (
    <div {...storyblokEditable(blok)}>
      <ProductDescription product={product} />
    </div>
  );
};

export default StoryblokProductDescription;
