import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import React from "react";

const StoryblokProducList = ({ blok, products }) => {
  const gap = blok.gap ? `gap-${blok.gap}` : `gap-3`;
  const spacing = blok.spacing
    ? `xl:grid-cols-${blok.spacing}`
    : `xl:grid-cols-4`;
  const bg = blok.bg === "bg-gray-200" ? blok.bg : ``;
  const padding = blok.padding ? blok.padding : `p-3`;

  return (
    <div
      className={`mb-6 grid xs:grid-cols-2 md:grid-cols-3 ${spacing} ${bg} ${gap} ${padding}`}
    >
      {products?.map((product, index) => (
        <div {...storyblokEditable(blok)} key={index}>
          {blok.columns.map((nestedBlok) => (
            <StoryblokComponent
              blok={nestedBlok}
              product={product}
              key={product?.id}
              index={index}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default StoryblokProducList;
