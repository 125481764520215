import * as Types from "../types.generated";

import { gql } from "@apollo/client";
export type ImageFragment = {
  alt: string;
  height: string;
  title?: string | null;
  url: string;
  width: string;
};

export const ImageFragmentDoc = gql`
  fragment Image on Image {
    alt
    height
    title
    url
    width
  }
`;
