import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { useSession } from "next-auth/react";
import { Customer } from "../graphql/types.generated";
import {
  GetCustomerDocument,
  GetCustomerQuery,
  GetCustomerQueryVariables,
} from "../graphql/customer/GetCustomer.query.generated";

type UseGetCustomer = {
  getCustomer: () => Promise<Customer | null>;
};

export const useGetCustomer = (): UseGetCustomer => {
  const client = useApolloClient();
  const { data: session } = useSession();

  const get = useCallback(async (): Promise<Customer | null> => {
    if (!session) {
      throw new Error(
        "Failed to get the customer data. Authorization is required!"
      );
    }
    const { data } = await client.query<
      GetCustomerQuery,
      GetCustomerQueryVariables
    >({
      query: GetCustomerDocument,
    });
    if (data.myCustomer) {
      return data.myCustomer;
    }
    return null;
  }, [client, session]);

  return {
    getCustomer: get,
  };
};
