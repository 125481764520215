import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import { ProductListFragmentDoc } from "./ProductList.fragment.generated";
import * as Apollo from "@apollo/client";
export type GetProductListQueryVariables = Types.Exact<{
  categorySlug: Types.Scalars["String"];
  facets?: Types.InputMaybe<
    Array<Types.FilterAttribute> | Types.FilterAttribute
  >;
  search?: Types.InputMaybe<Types.Scalars["String"]>;
  locale: Types.Scalars["String"];
  page?: Types.InputMaybe<Types.Scalars["Int"]>;
  perPage?: Types.InputMaybe<Types.Scalars["Int"]>;
  currencyCode?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetProductListQuery = {
  productList?: {
    products?: Array<{
      id: string;
      slug?: string | null;
      name?: string | null;
      description?: string | null;
      attributeDefinitions?: Array<{
        attributeConstraint: string;
        label?: string | null;
        name: string;
        type: any;
      }> | null;
      variants: Array<{
        id: string;
        sku: string;
        isMaster: boolean;
        isMatching: boolean;
        attributesRaw: Array<{ name: string; value: any }>;
        prices: Array<{
          centAmount: number;
          discountedCentAmount?: number | null;
          taxType: string;
        }>;
      }>;
      images?: Array<{
        alt: string;
        height: string;
        title?: string | null;
        url: string;
        width: string;
      }> | null;
    }> | null;
    facets?: Array<{
      name: string;
      type: string;
      dataType: string;
      label?: string | null;
      values?: Array<
        | {
            from: number;
            fromStr?: string | null;
            to?: number | null;
            toStr?: string | null;
            count: number;
            productCount?: number | null;
            total: number;
            min: number;
            max: number;
            mean: number;
          }
        | { term: string; count: number; label?: string | null }
      > | null;
      displayOptions?: Array<{ key: string; value: string }> | null;
    }> | null;
    metadata: { limit: number; count: number; offset: number; total: number };
  } | null;
};

export const GetProductListDocument = gql`
  query GetProductList(
    $categorySlug: String!
    $facets: [FilterAttribute!]
    $search: String
    $locale: String!
    $page: Int
    $perPage: Int
    $currencyCode: String
  ) {
    productList(
      filter: {
        categorySlug: $categorySlug
        attributes: $facets
        text: $search
      }
      locale: $locale
      page: $page
      perPage: $perPage
      currencyCode: $currencyCode
    ) {
      ...ProductList
    }
  }
  ${ProductListFragmentDoc}
`;
export type GetProductListQueryResult = Apollo.QueryResult<
  GetProductListQuery,
  GetProductListQueryVariables
>;
