import { Breadcrumb, Heading } from "@precomposer/ui";
import { useTranslation } from "next-i18next";
import AccountBillingAddressForm from "../../account/AccountBillingAddressForm";
import AccountShippingAddressForm from "../../account/AccountShippingAddressForm";
import Meta from "../../layout/Meta";

const AccountAddressCMS = ({ flex, gap }) => {
  const { t } = useTranslation(["common", "account"]);

  return (
    <div style={{ display: "flex", flexDirection: flex }}>
      <Meta title={t("account:address.title")} nofollow={true} noindex={true} />
      <Breadcrumb>
        <Breadcrumb.Item href={"/"}>{t("common:home.title")}</Breadcrumb.Item>
        <Breadcrumb.Item href={"/account"}>
          {t("account:dashboard.title")}
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t("account:address.title")}</Breadcrumb.Item>
      </Breadcrumb>
      <Heading className={"mb-6"}>{t("account:address.headline")}</Heading>
      <p className={"mb-4"}>{t("account:address.text")}</p>
      <div
        style={{ display: "flex", flexDirection: "column", gap: `${gap}px` }}
      >
        <div style={{ flex: 1 }}>
          <AccountBillingAddressForm />
        </div>
        <div style={{ flex: 1 }}>
          <AccountShippingAddressForm />
        </div>
      </div>
    </div>
  );
};

export default AccountAddressCMS;
