import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { OrderItemFragmentDoc } from "./OrderItem.fragment.generated";
export type OrderFragment = {
  orderNumber: string;
  createdAt: string;
  totalPriceInCent: number;
  currencyCode?: string | null;
  orderState: string;
  orderItems: Array<{
    productId: string;
    productName: string;
    productSlug: string;
    sku: string;
    totalPriceInCent: number;
    priceInCent: number;
    quantity: number;
  }>;
};

export const OrderFragmentDoc = gql`
  fragment Order on Order {
    orderNumber
    createdAt
    orderItems {
      ...OrderItem
    }
    totalPriceInCent
    currencyCode
    orderState
  }
  ${OrderItemFragmentDoc}
`;
