import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { Address, useCart, useCustomer } from "@precomposer/data";
import {
  checkoutBillingAddressFields,
  CheckoutBillingAddressFieldset,
  checkoutBillingAddressSchema,
} from "./CheckoutBillingAddressFieldset";
import {
  checkoutShippingAddressFields,
  CheckoutShippingAddressFieldset,
  checkoutShippingAddressSchema,
} from "./CheckoutShippingAddressFieldset";
import {
  checkoutEmailAddressFields,
  CheckoutEmailAddressFieldset,
  checkoutEmailAddressSchema,
} from "./CheckoutEmailAddressFieldset";
import { Button, Checkbox, Dropdown } from "@precomposer/ui";
import AccountSignInForm from "../account/AccountSignInForm";

type Props = {
  setStep: Dispatch<SetStateAction<number>>;
};

const CheckoutAddressForm: FC<Props> = ({ setStep }) => {
  const { t } = useTranslation(["checkout"]);
  const { setCartAddressAction, cartData } = useCart();
  const { customerData } = useCustomer();
  const { data: session } = useSession();
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(false);
  const [validation, setValidation] = useState({});
  const [initialValues, setInitialValues] = useState({
    ...checkoutBillingAddressFields,
    ...checkoutShippingAddressFields,
    ...checkoutEmailAddressFields,
  });

  useEffect(() => {
    setInitialValues({
      billingAddress: (cartData.billingAddress ||
        customerData.addresses.find(({ type }) => type === "billingAddress") ||
        checkoutBillingAddressFields.billingAddress) as Address,
      shippingAddress: (customerData.addresses.find(
        ({ type }) => type === "shippingAddress"
      ) || checkoutShippingAddressFields.shippingAddress) as Address,
      email: cartData.customerEmail || checkoutEmailAddressFields.email,
    });
  }, [setInitialValues, cartData, customerData, billingSameAsShipping]);

  useEffect(() => {
    let scheme = Yup.object()
      .shape({
        billingAddress: checkoutBillingAddressSchema,
      })
      .concat(
        billingSameAsShipping
          ? Yup.object().shape({
              shippingAddress: checkoutShippingAddressSchema,
            })
          : Yup.object().strip()
      );
    if (!session) {
      scheme = checkoutEmailAddressSchema.concat(scheme);
    }
    setValidation(scheme);
  }, [billingSameAsShipping, setValidation, session]);

  return (
    <div className={"mb-6 py-4"}>
      {!session && (
        <div className={"mb-6 border-b py-4"}>
          <Dropdown>
            <Dropdown.Toggle className={"pb-3"}>
              {t("checkout:addresses.form.dropdown-login")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <AccountSignInForm className={"grid gap-4 md:grid-cols-2"} />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        enableReinitialize={true}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          const addressAction = {
            setBillingAddressAction: { billingAddress: values.billingAddress },
            setShippingAddressAction: {
              shippingAddress: billingSameAsShipping
                ? values.shippingAddress
                : values.billingAddress,
            },
          };
          if (!session) {
            Object.assign(addressAction, {
              setCustomerEmailAction: { email: values.email },
            });
          }
          await setCartAddressAction(addressAction);
          setSubmitting(false);
          resetForm({
            values: {
              ...checkoutBillingAddressFields,
              ...checkoutShippingAddressFields,
              ...checkoutEmailAddressFields,
            },
          });
          setStep(2);
        }}
      >
        {(formik) => (
          <Form>
            <CheckoutEmailAddressFieldset />
            <CheckoutBillingAddressFieldset />
            <div className={"pb-3"}>
              <Checkbox
                id={"billing-same-as-shipping"}
                label={t("checkout:addresses.form.billing-same-shipping")}
                checked={billingSameAsShipping}
                onChange={() =>
                  setBillingSameAsShipping(!billingSameAsShipping)
                }
              />
            </div>
            {billingSameAsShipping && <CheckoutShippingAddressFieldset />}
            <Button
              type={"submit"}
              size={"medium"}
              variant={"primary"}
              disabled={formik.isSubmitting}
            >
              {t("checkout:addresses.form.next")}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CheckoutAddressForm;
