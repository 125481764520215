import React, { ChangeEvent } from "react";
import { Maybe, ProductVariant } from "@precomposer/data";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Select } from "@precomposer/ui";

type Props = {
  variants: ProductVariant[];
  variant: ProductVariant;
  productSlug: Maybe<string> | undefined;
};

const ProductOptions = ({ variants, variant, productSlug }: Props) => {
  const { t } = useTranslation(["product"]);
  const router = useRouter();

  const selectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const variant = variants.find(
      (variant) => variant.id === event.target.value
    );
    if (variant) {
      router.push(`/p/${productSlug}#sku=${variant.sku}`);
    }
  };

  return (
    <>
      {variants.length > 1 && (
        <Select
          id={"product-option"}
          onChange={selectChange}
          fullWidth
          value={variant.id}
          label={t("product:size")}
        >
          {variants.map((variant) => {
            return (
              <Select.Option key={variant.id} value={variant.id}>
                {
                  variant.attributesRaw.find((attr) => attr.name === "size")
                    ?.value
                }
              </Select.Option>
            );
          })}
        </Select>
      )}
    </>
  );
};

export default ProductOptions;
