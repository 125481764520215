import React, { FC, ReactNode } from "react";
import NextLink from "next/link";

export type LinkProps = {
  url: string;
  children: ReactNode;
  id?: string;
  linktype?: string;
  fieldtype?: string;
  cached_url?: string;
};

const Link: FC<LinkProps> = ({
  url,
  children,
  id,
  linktype,
  fieldtype,
  cached_url,
}) => {
  return <NextLink href={url}>{children}</NextLink>;
};

export default Link;
