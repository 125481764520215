import Meta from "../../layout/Meta";
import { Heading } from "@precomposer/ui";
import CheckoutSteps from "../../checkout/CheckoutSteps";
import React, { FC, ReactNode } from "react";
import { useTranslation } from "next-i18next";

type Props = {
  children: ReactNode;
};

const CheckoutPage: FC<Props> = ({ children }) => {
  const { t } = useTranslation(["checkout"]);

  return (
    <>
      <Meta title={t("checkout:title")} nofollow={true} noindex={true} />
      <section>
        <header>
          <Heading className={"mb-6"}>{t("checkout:headline")}</Heading>
        </header>
      </section>
      <div>{children}</div>
    </>
  );
};

export default CheckoutPage;
