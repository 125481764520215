import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { CustomerFragmentDoc } from "./Customer.fragment.generated";
import * as Apollo from "@apollo/client";
export type SignInCustomerQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
  password: Types.Scalars["String"];
  anonymousCartId?: Types.InputMaybe<Types.Scalars["String"]>;
  rememberMe?: Types.InputMaybe<Types.Scalars["Boolean"]>;
}>;

export type SignInCustomerQuery = {
  signInCustomer?: {
    id: string;
    version: number;
    email: string;
    isEmailVerified?: boolean | null;
    token?: string | null;
    defaultBillingAddressKey?: string | null;
    defaultShippingAddressKey?: string | null;
    addresses: Array<{
      key?: string | null;
      type?: string | null;
      streetName?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      streetNumber?: string | null;
      postalCode?: string | null;
      city?: string | null;
      country?: string | null;
      company?: string | null;
    }>;
  } | null;
};

export const SignInCustomerDocument = gql`
  query SignInCustomer(
    $email: String!
    $password: String!
    $anonymousCartId: String
    $rememberMe: Boolean
  ) {
    signInCustomer(
      email: $email
      password: $password
      anonymousCartId: $anonymousCartId
      rememberMe: $rememberMe
    ) {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;
export type SignInCustomerQueryResult = Apollo.QueryResult<
  SignInCustomerQuery,
  SignInCustomerQueryVariables
>;
