import React, { FC, useCallback, useEffect, useState } from "react";

type Props = {
  children: any;
  isPermanent: boolean;
  timer?: number;
  bgColor: string;
  onClose?: any;
};

const NotificationBanner: FC<Props> = ({
  children,
  isPermanent,
  bgColor,
  timer,
  onClose,
}) => {
  const [isVisible, setVisible] = useState(true);

  const hideBanner = () => {
    setVisible(false);
  };

  const escFunction = useCallback((event: { key: string }) => {
    if (event.key === "Escape") {
      hideBanner();
    }
  }, []);

  useEffect(() => {
    if (!isPermanent) {
      document.addEventListener("keydown", escFunction, false);
      return () => {
        document.removeEventListener("keydown", escFunction, false);
      };
    }
  });

  useEffect(() => {
    if (!isPermanent && timer) {
      const timeout = setTimeout(() => onClose(), timer);
      return () => clearTimeout(timeout);
    }
  }, [isPermanent, onClose, timer]);

  return (
    <>
      {isVisible && children && (
        <div className={`grid grid-cols-8 ${bgColor}`}>
          <div className="col-span-6 col-start-2 flex place-self-center">
            {children}
          </div>
          {!isPermanent && (
            <div className="justify-end self-start justify-self-end">
              <button onClick={hideBanner}>X</button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default NotificationBanner;
