const config = {
  companyName: "eCube",

  placeholderImg: "product-img-placeholder.svg",

  productsPerPage: 9,

  title: "Precomposer",

  titleTemplate: "%s | Precomposer - The shop template",

  themeColor: "#ffffff",
};

export default config;
