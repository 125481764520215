import React from "react";
import EmptyCartContent from "../../cms/cart/EmptyCartContent";
import { cn } from "../../../lib/tailwind-merge.util";
import { storyblokEditable } from "@storyblok/react";
import NotFoundPageContent from "../../cms/not-found-page/NotFoundPageContent";

const StoryblokEmptyCartContent = ({ blok }) => {
  const { info, btn_text, btn_link, btn_variant, btn_size } = blok;
  return (
    <div className={`flex ${blok.alignment} p-6`} {...storyblokEditable(blok)}>
      <EmptyCartContent
        info={info}
        btn_text={btn_text}
        btn_link={btn_link}
        btn_variant={btn_variant}
        btn_size={btn_size}
      />
    </div>
  );
};
export default StoryblokEmptyCartContent;
