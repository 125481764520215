import React, { FC } from "react";
import { Product } from "@precomposer/data";
import { Heading } from "@precomposer/ui";

type Props = {
  product: Product;
};

const ProductName: FC<Props> = ({ product }) => {
  return <Heading className={"pb-3"}>{product.name}</Heading>;
};

export default ProductName;
