import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import Grid from "../../cms/Grid";

const StoryblokGrid = ({ blok }) => {
  return (
    <Grid {...storyblokEditable(blok)}>
      {blok.children.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </Grid>
  );
};

export default StoryblokGrid;
