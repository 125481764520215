import * as Types from "../types.generated";

import { gql } from "@apollo/client";
export type CartAddressFragment = {
  city: string;
  company?: string | null;
  country: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  streetName: string;
  streetNumber: string;
};

export const CartAddressFragmentDoc = gql`
  fragment CartAddress on CartAddress {
    city
    company
    country
    firstName
    lastName
    postalCode
    streetName
    streetNumber
  }
`;
