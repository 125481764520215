import React, { FC } from "react";
import classNames from "classnames";
import {
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
  IdentificationIcon,
} from "@heroicons/react/24/outline";

type Props = {
  step: number;
};

const CheckoutStepsCMS: FC<Props> = ({ step }) => {
  if (step < 1) {
    return <div></div>;
  } else {
    return (
      <>
        <ol
          className={
            "flex w-full items-center text-base font-medium text-gray-500"
          }
        >
          <li
            className={classNames(
              "flex w-full items-center after:inline-block after:h-1 after:w-full after:content-['']",
              step > 1 ? "after:bg-blue-500" : "after:bg-gray-100"
            )}
          >
            <span
              className={classNames(
                "flex h-10 w-10 shrink-0 items-center justify-center rounded-full lg:h-12 lg:w-12",
                step > 1 ? "bg-blue-500 text-white" : "bg-gray-100"
              )}
            >
              <IdentificationIcon className={"inline-block h-6 w-6"} />
            </span>
          </li>
          <li
            className={classNames(
              "flex w-full items-center after:inline-block after:h-1 after:w-full after:content-['']",
              step > 2 ? "after:bg-blue-500" : "after:bg-gray-100"
            )}
          >
            <span
              className={classNames(
                "flex h-10 w-10 shrink-0 items-center justify-center rounded-full lg:h-12 lg:w-12",
                step > 2 ? "bg-blue-500 text-white" : "bg-gray-100"
              )}
            >
              <CreditCardIcon className={"inline-block h-6 w-6"} />
            </span>
          </li>
          <li className={"flex items-center"}>
            <span
              className={
                "flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-gray-100 lg:h-12 lg:w-12"
              }
            >
              <ClipboardDocumentCheckIcon className={"inline-block h-6 w-6"} />
            </span>
          </li>
        </ol>
      </>
    );
  }
};

export default CheckoutStepsCMS;
