import React from "react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const StoryblokCartComponent = ({ blok, cartData }) => {

const cartComponent = cartData.cartItems.length === 0 ? blok.cart_empty : blok.cart_filled;

  return (
    <div {...storyblokEditable(blok)}>
      {cartComponent.map((nestedBlok) => {
        return (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            cartData={cartData}
          />
        );
      })}
    </div>
  );
};
export default StoryblokCartComponent;
