import { storyblokEditable } from "@storyblok/react";
import React from "react";
import { Product } from "@precomposer/data";
import ProductName from "../../cms/product-detail/ProductName";

const StoryblokProductName = ({ blok, ...restProps }) => {
  const product: Product = restProps.product;

  return (
    <div {...storyblokEditable(blok)}>
      <ProductName product={product} />
    </div>
  );
};

export default StoryblokProductName;
