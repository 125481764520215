import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import { CategoryTreeItemFragmentDoc } from "./CategoryTreeItem.fragment.generated";
import * as Apollo from "@apollo/client";
export type GetCategoryTreeQueryVariables = Types.Exact<{
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetCategoryTreeQuery = {
  categoryTree?: {
    root?: Array<{
      children?: Array<{
        children?: Array<{
          children?: Array<{
            category: { id: string; name: string; slug: string };
          }> | null;
          category: { id: string; name: string; slug: string };
        }> | null;
        category: { id: string; name: string; slug: string };
      }> | null;
      category: { id: string; name: string; slug: string };
    }> | null;
  } | null;
};

export const GetCategoryTreeDocument = gql`
  query GetCategoryTree($locale: String) {
    categoryTree(locale: $locale) {
      root {
        ...CategoryTreeItem
        children {
          ...CategoryTreeItem
          children {
            ...CategoryTreeItem
            children {
              ...CategoryTreeItem
            }
          }
        }
      }
    }
  }
  ${CategoryTreeItemFragmentDoc}
`;
export type GetCategoryTreeQueryResult = Apollo.QueryResult<
  GetCategoryTreeQuery,
  GetCategoryTreeQueryVariables
>;
