import React from "react";
import { useTranslation } from "next-i18next";
import { Button, Heading } from "@precomposer/ui";

const EmptyCart = () => {
  const { t } = useTranslation(["cart"]);

  return (
    <div className={"mt-4"}>
      <Heading element={"h2"} className={"mb-4"}>
        {t("cart:empty-cart.heading")}
      </Heading>
      <p className={"mb-4"}>{t("cart:empty-cart.text")}</p>
      <Button href={"/"} variant={"secondary"}>
        {t("cart:empty-cart.add-items")}
      </Button>
    </div>
  );
};

export default EmptyCart;
