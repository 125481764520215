import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import { ProductFragmentDoc } from "./Product.fragment.generated";
import { FacetFragmentDoc } from "./Facet.fragment.generated";
import { MetadataFragmentDoc } from "./Metadata.fragment.generated";
export type ProductListFragment = {
  products?: Array<{
    id: string;
    slug?: string | null;
    name?: string | null;
    description?: string | null;
    attributeDefinitions?: Array<{
      attributeConstraint: string;
      label?: string | null;
      name: string;
      type: any;
    }> | null;
    variants: Array<{
      id: string;
      sku: string;
      isMaster: boolean;
      isMatching: boolean;
      attributesRaw: Array<{ name: string; value: any }>;
      prices: Array<{
        centAmount: number;
        discountedCentAmount?: number | null;
        taxType: string;
      }>;
    }>;
    images?: Array<{
      alt: string;
      height: string;
      title?: string | null;
      url: string;
      width: string;
    }> | null;
  }> | null;
  facets?: Array<{
    name: string;
    type: string;
    dataType: string;
    label?: string | null;
    values?: Array<
      | {
          from: number;
          fromStr?: string | null;
          to?: number | null;
          toStr?: string | null;
          count: number;
          productCount?: number | null;
          total: number;
          min: number;
          max: number;
          mean: number;
        }
      | { term: string; count: number; label?: string | null }
    > | null;
    displayOptions?: Array<{ key: string; value: string }> | null;
  }> | null;
  metadata: { limit: number; count: number; offset: number; total: number };
};

export const ProductListFragmentDoc = gql`
  fragment ProductList on ProductList {
    products {
      ...Product
    }
    facets {
      ...Facet
    }
    metadata {
      ...Metadata
    }
  }
  ${ProductFragmentDoc}
  ${FacetFragmentDoc}
  ${MetadataFragmentDoc}
`;
