import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { CustomerFragmentDoc } from "./Customer.fragment.generated";
import * as Apollo from "@apollo/client";
export type GetCustomerQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetCustomerQuery = {
  myCustomer?: {
    id: string;
    version: number;
    email: string;
    isEmailVerified?: boolean | null;
    token?: string | null;
    defaultBillingAddressKey?: string | null;
    defaultShippingAddressKey?: string | null;
    addresses: Array<{
      key?: string | null;
      type?: string | null;
      streetName?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      streetNumber?: string | null;
      postalCode?: string | null;
      city?: string | null;
      country?: string | null;
      company?: string | null;
    }>;
  } | null;
};

export const GetCustomerDocument = gql`
  query GetCustomer {
    myCustomer {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;
export type GetCustomerQueryResult = Apollo.QueryResult<
  GetCustomerQuery,
  GetCustomerQueryVariables
>;
