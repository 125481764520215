import React, { FC, useEffect, useState } from "react";
import { CartItem, useCart } from "@precomposer/data";
import { firstImageUrl } from "../../../lib/util";
import Link from "next/link";
import Image from "next/image";
import { Button, Stepper } from "@precomposer/ui";
import ProductPrice from "../../product/ProductPrice";
import { TrashIcon } from "@heroicons/react/24/outline";

type Props = {
  item: CartItem;
  imageEnabled: boolean;
};

const CmsCartItem: FC<Props> = ({ item, imageEnabled }) => {
  const { changeLineItemQuantityAction, removeLineItemAction } = useCart();
  const [qty, setQty] = useState<number>(item.quantity);

  useEffect(() => {
    if (qty !== item.quantity) {
      changeLineItemQuantityAction({ lineItemId: item.id, quantity: qty });
    }
  }, [qty, item, changeLineItemQuantityAction]);

  const handleRemoveItem = async () => {
    await removeLineItemAction({ lineItemId: item.id });
  };

  return (
    <div
      className={
        "grid grid-cols-2 grid-rows-3 gap-2 py-3 lg:grid-cols-5 lg:grid-rows-1"
      }
    >
      {imageEnabled && (
        <div className={"row-span-3 lg:row-auto"}>
          <Image
            src={firstImageUrl(item?.images)}
            alt={item.productName}
            width={135}
            height={180}
          />
        </div>
      )}
      <div className={imageEnabled ? "lg:col-span-2" : "lg:col-span-3"}>
        <Link href={`/p/${item.productSlug}#sku=${item.sku}`}>
          {item.productName}
        </Link>
      </div>
      <div
        className={
          "place-self-end font-bold lg:place-self-start lg:font-normal"
        }
      >
        <ProductPrice centAmount={item.totalGrossPriceInCent} />
      </div>
      <div className={"flex items-end justify-between lg:items-start"}>
        <Stepper qty={qty} setQty={setQty} />
        <Button onClick={handleRemoveItem}>
          <TrashIcon className={"block h-6 w-6"} aria-hidden={"true"} />
        </Button>
      </div>
    </div>
  );
};

export default CmsCartItem;
