import React from "react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const StoryblokCartContentContainer = ({ blok, cartData }) => {
  const { summaryBelowItems } = blok;
  const containerStyle = summaryBelowItems
    ? "flex flex-col gap-6"
    : "lg:flex lg:gap-6";

  return (
    <div className={containerStyle} {...storyblokEditable(blok)}>
      {blok.children.map((nestedBlok) => {
        return (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            cartData={cartData}
          />
        );
      })}
    </div>
  );
};
export default StoryblokCartContentContainer;
