import React, { FC } from "react";
import { Button, Stepper } from "@precomposer/ui";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "next-i18next";

type Props = {
  qty: number;
  setQty: React.Dispatch<React.SetStateAction<number>>;
  addToCart: any;
};

const StepperAddToCart: FC<Props> = ({ qty, setQty, addToCart }) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <Stepper qty={qty} setQty={setQty}></Stepper>
      <Button variant="primary" className={"flex"} onClick={addToCart}>
        <ShoppingCartIcon className={"mr-2 block h-6 w-6"} />
        {t("product:add-to-cart")}
      </Button>
    </>
  );
};

export default StepperAddToCart;
