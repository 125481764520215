import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { OrderFragmentDoc } from "./Order.fragment.generated";
import { MetadataFragmentDoc } from "../catalog/product-list/Metadata.fragment.generated";
export type OrderListFragment = {
  orders: Array<{
    orderNumber: string;
    createdAt: string;
    totalPriceInCent: number;
    currencyCode?: string | null;
    orderState: string;
    orderItems: Array<{
      productId: string;
      productName: string;
      productSlug: string;
      sku: string;
      totalPriceInCent: number;
      priceInCent: number;
      quantity: number;
    }>;
  } | null>;
  metadata: { limit: number; count: number; offset: number; total: number };
};

export const OrderListFragmentDoc = gql`
  fragment OrderList on OrderList {
    orders {
      ...Order
    }
    metadata {
      ...Metadata
    }
  }
  ${OrderFragmentDoc}
  ${MetadataFragmentDoc}
`;
