import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type SignOutCustomerQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SignOutCustomerQuery = { signOutCustomer?: boolean | null };

export const SignOutCustomerDocument = gql`
  query SignOutCustomer {
    signOutCustomer
  }
`;
export type SignOutCustomerQueryResult = Apollo.QueryResult<
  SignOutCustomerQuery,
  SignOutCustomerQueryVariables
>;
