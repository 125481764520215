import React from "react";
import { useTranslation } from "next-i18next";
import { Product } from "@precomposer/data";
import {
  firstImageUrl,
  getMinVariantsDiscount,
  getMinVariantsPrice,
} from "../../lib/util";
import Link from "next/link";
import Image from "next/image";
import { Button, CardCMS } from "@precomposer/ui";
import ProductPrice from "../product/ProductPrice";

// type Props = {
//   product: Product;
//   index: number;
//   frameColor: string;
//   btnColor: string;
// };

const ProductCardCMS = ({
  product,
  index,
  frameColor,
  btnColor,
  hasShadow,
  borderRadius,
  float,
}) => {
  const { t } = useTranslation(["product"]);

  function getMasterVariantHref(product: Product): string {
    let masterVariant = product.variants.find((variant) => {
      return variant.isMaster;
    });

    return `/p/${product.slug}#sku=${masterVariant?.sku}`;
  }

  return (
    <CardCMS
      style={{
        border: `1px solid ${frameColor}`, // Adds a grey frame
        borderRadius: `${borderRadius}px`, // Rounds the corners of the border
        boxShadow:
          hasShadow == "true" ? `0 4px 8px ${frameColor}` : "" /* box shadow */,
        overflow: "hidden",
      }}
    >
      <Link href={getMasterVariantHref(product)}>
        <CardCMS.Img>
          <Image
            src={firstImageUrl(product?.images)}
            alt={product?.name || "Product Image"}
            width={135}
            height={180}
            priority={index < 4}
          />
        </CardCMS.Img>
      </Link>
      <CardCMS.Body>
        <CardCMS.Title>{product.name}</CardCMS.Title>
        <CardCMS.Text>
          <span className="flex flex-row items-center space-x-2">
            <span className={"text-sm"}>{t("product:from")}</span>
            <ProductPrice
              centAmount={getMinVariantsPrice({ variants: product.variants })}
              discountedCentAmount={getMinVariantsDiscount({
                variants: product.variants,
              })}
            />
          </span>
        </CardCMS.Text>
      </CardCMS.Body>
      <CardCMS.Footer>
        <div style={{ float: float }}>
          <Button href={getMasterVariantHref(product)} variant={btnColor}>
            {"Details"}
          </Button>
        </div>
      </CardCMS.Footer>
    </CardCMS>
  );
};

export default ProductCardCMS;
