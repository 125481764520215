import { storyblokEditable } from "@storyblok/react";
import React from "react";
import { Product } from "@precomposer/data";
import ProductImage from "../../cms/product-detail/ProductImage";

const StoryblokProductImage = ({ blok, ...restProps }) => {
  const product: Product = restProps.product;

  return (
    <div className="md:flex md:w-3/5 md:flex-row" {...storyblokEditable(blok)}>
      <ProductImage product={product} />
    </div>
  );
};

export default StoryblokProductImage;
