import { useTranslation } from "next-i18next";
import { Button } from "@precomposer/ui";

const CheckoutButton = () => {
  const { t } = useTranslation(["cart"]);

  return (
    <div>
      <Button
        variant={"primary"}
        size={"large"}
        href={"/checkout"}
        className={"block w-full text-center"}
      >
        {t("cart:checkout")}
      </Button>
    </div>
  );
};

export default CheckoutButton;
