import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
export type AttributeDefinitionFragment = {
  attributeConstraint: string;
  label?: string | null;
  name: string;
  type: any;
};

export const AttributeDefinitionFragmentDoc = gql`
  fragment AttributeDefinition on AttributeDefinition {
    attributeConstraint
    label
    name
    type
  }
`;
