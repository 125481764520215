import { storyblokEditable } from "@storyblok/react";
import React from "react";
import CheckoutStepContent from "../../cms/checkout/CheckoutStepContent";

const StoryblokCheckoutStepContent = ({ blok, ...restProps }) => {
  const step: number = restProps.step;
  const setStep: React.Dispatch<React.SetStateAction<number>> =
    restProps.setStep;

  return (
    <div {...storyblokEditable(blok)}>
      <CheckoutStepContent step={step} setStep={setStep} />
    </div>
  );
};

export default StoryblokCheckoutStepContent;
