import React from "react";
import { Cart } from "@precomposer/data";
import CmsCartItem from "./CmsCartItem";

type TextProps = {
  article: string;
  price: string;
  quantity: string;
};
type CartItemContainerProps = {
  cartData: Cart;
  imageEnabled: boolean;
} & TextProps;

const CmsCartItemContainer: React.FC<CartItemContainerProps> = ({
  cartData,
  article,
  price,
  quantity,
  imageEnabled,
}) => {
  return (
    <div className={"pb-6 lg:flex-1"}>
      <div
        className={
          "hidden font-bold lg:grid lg:grid-cols-5 lg:gap-2 lg:border-y lg:py-3"
        }
      >
        <div className={"lg:col-span-3"}>{article}</div>
        <div>{price}</div>
        <div>{quantity}</div>
      </div>
      {cartData.cartItems?.map((product) => (
        <CmsCartItem
          item={product}
          key={product?.id}
          imageEnabled={imageEnabled}
        />
      ))}
    </div>
  );
};

export default CmsCartItemContainer;
