import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  GetOrderListDocument,
  GetOrderListQuery,
  GetOrderListQueryVariables,
} from "../graphql/order/GetOrderList.query.generated";

/**
 * __useGetOrderList__
 *
 * To run a query within a React component, call `useGetOrderList` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param options options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderList({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetOrderList(
  options?: QueryHookOptions<GetOrderListQuery, GetOrderListQueryVariables>
) {
  return useQuery<GetOrderListQuery, GetOrderListQueryVariables>(
    GetOrderListDocument,
    options
  );
}
