import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
export type CategoryFragment = {
  id: string;
  key?: string | null;
  name: string;
  slug: string;
  description?: string | null;
};

export const CategoryFragmentDoc = gql`
  fragment Category on Category {
    id
    key
    name
    slug
    description
  }
`;
