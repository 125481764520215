import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import React from "react";
import ProductDetailPageComponent from "../../cms/product-detail/ProductDetailPageComponent";
import { Product, ProductVariant } from "@precomposer/data";

const StoryblokProductDetailPage = ({ blok, ...restProps }) => {
  const product: Product = restProps.product;
  const addToCartSuccess: boolean = restProps.addToCartSuccess;
  const onNotificationBannerClose: void = restProps.onNotificationBannerClose;
  const variant: ProductVariant = restProps.variant;
  const qty: number = restProps.qty;
  const setQty: React.Dispatch<React.SetStateAction<number>> = restProps.setQty;
  const addToCart: any = restProps.addToCart;

  return (
    <div {...storyblokEditable(blok)}>
      <ProductDetailPageComponent
        product={product}
        addToCartSuccess={addToCartSuccess}
        onNotificationBannerClose={onNotificationBannerClose}
      >
        {blok.children ? (
          blok.children.map((nestedBlok) => (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
              product={product}
              variant={variant}
              qty={qty}
              setQty={setQty}
              addToCart={addToCart}
            />
          ))
        ) : (
          <p>NO CHILDREN</p>
        )}
      </ProductDetailPageComponent>
    </div>
  );
};

export default StoryblokProductDetailPage;
