import * as Types from "../types.generated";

import { gql } from "@apollo/client";
export type OrderItemFragment = {
  productId: string;
  productName: string;
  productSlug: string;
  sku: string;
  totalPriceInCent: number;
  priceInCent: number;
  quantity: number;
};

export const OrderItemFragmentDoc = gql`
  fragment OrderItem on OrderItem {
    productId
    productName
    productSlug
    sku
    totalPriceInCent
    priceInCent
    quantity
  }
`;
