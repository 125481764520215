import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { CustomerFragmentDoc } from "./Customer.fragment.generated";
import * as Apollo from "@apollo/client";
export type SignUpCustomerMutationVariables = Types.Exact<{
  customerData: Types.SignUpCustomerData;
  anonymousCartId?: Types.InputMaybe<Types.Scalars["String"]>;
  rememberMe?: Types.InputMaybe<Types.Scalars["Boolean"]>;
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
  currencyCode?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type SignUpCustomerMutation = {
  signUpCustomer?: {
    id: string;
    version: number;
    email: string;
    isEmailVerified?: boolean | null;
    token?: string | null;
    defaultBillingAddressKey?: string | null;
    defaultShippingAddressKey?: string | null;
    addresses: Array<{
      key?: string | null;
      type?: string | null;
      streetName?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      streetNumber?: string | null;
      postalCode?: string | null;
      city?: string | null;
      country?: string | null;
      company?: string | null;
    }>;
  } | null;
};

export const SignUpCustomerDocument = gql`
  mutation signUpCustomer(
    $customerData: SignUpCustomerData!
    $anonymousCartId: String
    $rememberMe: Boolean
    $locale: String
    $currencyCode: String
  ) {
    signUpCustomer(
      customerData: $customerData
      anonymousCartId: $anonymousCartId
      rememberMe: $rememberMe
      locale: $locale
      currencyCode: $currencyCode
    ) {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;
export type SignUpCustomerMutationFn = Apollo.MutationFunction<
  SignUpCustomerMutation,
  SignUpCustomerMutationVariables
>;
export type SignUpCustomerMutationResult =
  Apollo.MutationResult<SignUpCustomerMutation>;
export type SignUpCustomerMutationOptions = Apollo.BaseMutationOptions<
  SignUpCustomerMutation,
  SignUpCustomerMutationVariables
>;
