import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import { AttributeDefinitionFragmentDoc } from "./AttributeDefinition.fragment.generated";
import { ProductVariantFragmentDoc } from "./ProductVariant.fragment.generated";
import { ImageFragmentDoc } from "../../common/Image.fragment.generated";
import * as Apollo from "@apollo/client";
export type GetProductQueryVariables = Types.Exact<{
  productSlug: Types.Scalars["String"];
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
  currencyCode?: Types.InputMaybe<Types.Scalars["String"]>;
  includeCategoryReferences?: Types.InputMaybe<Types.Scalars["Boolean"]>;
}>;

export type GetProductQuery = {
  productBySlug?: {
    id: string;
    slug?: string | null;
    name?: string | null;
    description?: string | null;
    attributeDefinitions?: Array<{
      attributeConstraint: string;
      label?: string | null;
      name: string;
      type: any;
    }> | null;
    variants: Array<{
      id: string;
      sku: string;
      isMaster: boolean;
      isMatching: boolean;
      attributesRaw: Array<{ name: string; value: any }>;
      prices: Array<{
        centAmount: number;
        discountedCentAmount?: number | null;
        taxType: string;
      }>;
    }>;
    images?: Array<{
      alt: string;
      height: string;
      title?: string | null;
      url: string;
      width: string;
    }> | null;
    categoryReferences?: Array<{ id: string }> | null;
  } | null;
};

export const GetProductDocument = gql`
  query GetProduct(
    $productSlug: String!
    $locale: String
    $currencyCode: String
    $includeCategoryReferences: Boolean = false
  ) {
    productBySlug(
      slug: $productSlug
      locale: $locale
      currencyCode: $currencyCode
    ) {
      id
      slug
      name
      description
      attributeDefinitions {
        ...AttributeDefinition
      }
      variants {
        ...ProductVariant
      }
      images {
        ...Image
      }
      categoryReferences @include(if: $includeCategoryReferences) {
        id
      }
    }
  }
  ${AttributeDefinitionFragmentDoc}
  ${ProductVariantFragmentDoc}
  ${ImageFragmentDoc}
`;
export type GetProductQueryResult = Apollo.QueryResult<
  GetProductQuery,
  GetProductQueryVariables
>;
