import React from "react";
import CmsCartSummary from "../../cms/cart/CmsCartSummary";
import { Cart } from "@precomposer/data";
import { storyblokEditable } from "@storyblok/react";

type TextProps = {
  heading: string;
  item: string;
  subtotal: string;
  total: string;
  large_screen_size?: string;
  medium_screen_size?: string;
};

type SbCartSummaryProps = {
  blok: TextProps;
  cartData: Cart;
};

const StoryblokCartSummary: React.FC<SbCartSummaryProps> = ({
  blok,
  cartData,
}) => {
  const { heading, item, subtotal, total } = blok;
  return (
    <div className={"lg:w-1/5"} {...storyblokEditable(blok)}>
      <CmsCartSummary
        cartData={cartData}
        heading={heading}
        item={item}
        subtotal={subtotal}
        total={total}
      />
    </div>
  );
};

export default StoryblokCartSummary;
