import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import React from "react";
import Page from "../../cms/Page";

const StoryblokPage = ({ blok, cartData }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <Page title={blok.title}>
        {blok.children.map((nestedBlok) => {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} cartData={cartData} />;
        })}
      </Page>
    </div>
  );
};

export default StoryblokPage;
