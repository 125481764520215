import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { CartAddressFragmentDoc } from "./CartAddress.fragment.generated";
import { CartItemFragmentDoc } from "./CartItem.fragment.generated";
export type CartFragment = {
  id: string;
  cartState?: string | null;
  customerId?: string | null;
  customerEmail?: string | null;
  version: number;
  totalNetPriceInCent: number;
  totalGrossPriceInCent: number;
  currencyCode?: string | null;
  taxes?: Array<{ amount: number; name: string; rate: number }> | null;
  billingAddress?: {
    city: string;
    company?: string | null;
    country: string;
    firstName: string;
    lastName: string;
    postalCode: string;
    streetName: string;
    streetNumber: string;
  } | null;
  shippingAddress?: {
    city: string;
    company?: string | null;
    country: string;
    firstName: string;
    lastName: string;
    postalCode: string;
    streetName: string;
    streetNumber: string;
  } | null;
  cartItems: Array<{
    id: string;
    quantity: number;
    productId: string;
    productName: string;
    productSlug: string;
    sku: string;
    priceInCent: number;
    totalNetPriceInCent: number;
    totalGrossPriceInCent: number;
    images?: Array<{
      alt: string;
      height: string;
      title?: string | null;
      url: string;
      width: string;
    }> | null;
  }>;
};

export const CartFragmentDoc = gql`
  fragment Cart on Cart {
    id
    cartState
    customerId
    customerEmail
    version
    totalNetPriceInCent
    totalGrossPriceInCent
    currencyCode
    taxes {
      amount
      name
      rate
    }
    billingAddress {
      ...CartAddress
    }
    shippingAddress {
      ...CartAddress
    }
    cartItems {
      ...CartItem
    }
  }
  ${CartAddressFragmentDoc}
  ${CartItemFragmentDoc}
`;
