import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import React from "react";
import CheckoutPage from "../../cms/checkout/CheckoutPage";

const StoryblokCheckoutPage = ({ blok, ...restProps }) => {
  const step: number = restProps.step;
  const setStep: React.Dispatch<React.SetStateAction<number>> =
    restProps.setStep;

  return (
    <div {...storyblokEditable(blok)}>
      <CheckoutPage>
        {blok.children ? (
          blok.children.map((nestedBlok) => (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
              step={step}
              setStep={setStep}
            />
          ))
        ) : (
          <p>NO CHILDREN</p>
        )}
      </CheckoutPage>
    </div>
  );
};

export default StoryblokCheckoutPage;
