import React from "react";
import { storyblokEditable } from "@storyblok/react";
import CmsCartItemContainer from "../../cms/cart/CmsCartItemContainer";

const StoryblokCartItemsContainer = ({ blok, cartData }) => {
  const { article, price, quantity, image_enabled } = blok;
  return (
    <div {...storyblokEditable(blok)}>
      <CmsCartItemContainer
        cartData={cartData}
        article={article}
        price={price}
        quantity={quantity}
        imageEnabled={image_enabled}
      />
    </div>
  );
};

export default StoryblokCartItemsContainer;
