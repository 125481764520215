import React from "react";
import { useCart } from "@precomposer/data";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Heading } from "@precomposer/ui";
import ProductPrice from "../product/ProductPrice";
import { AppRoutes } from "../../lib/data/app-routes";

const CheckoutOrderSummary = () => {
  const { t } = useTranslation(["checkout"]);
  const { cartData } = useCart();

  return (
    <>
      <Heading element={"h2"} className={"mb-3 text-dynamic"}>
        {t("checkout:summary.heading")}
      </Heading>
      <dl className={"mb-3"}>
        <div className={"flex py-3"}>
          <dt className={"flex-1"}>{t("checkout:summary.subtotal")}</dt>
          <dd>
            <ProductPrice centAmount={cartData.totalNetPriceInCent} />
          </dd>
        </div>
        {cartData.taxes?.map((tax, index) => {
          return (
            <div key={index} className={"flex py-3"}>
              <dt className={"flex-1"}>{tax.name}</dt>
              <dd>
                <ProductPrice centAmount={tax.amount} />
              </dd>
            </div>
          );
        })}
        <div className={"flex border-t py-3 font-bold"}>
          <dt className={"flex-1"}>{t("checkout:summary.total")}</dt>
          <dd>
            <ProductPrice centAmount={cartData.totalGrossPriceInCent} />
          </dd>
        </div>
      </dl>
      <Link href={AppRoutes.CART}>{t("checkout:summary.edit-cart")}</Link>
    </>
  );
};

export default CheckoutOrderSummary;
