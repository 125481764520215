import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type GetStoryblokStoryQueryVariables = Types.Exact<{
  slug?: Types.InputMaybe<Types.Scalars["String"]>;
  position?: Types.InputMaybe<Types.Scalars["String"]>;
  name?: Types.InputMaybe<Types.Scalars["String"]>;
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetStoryblokStoryQuery = { storyblokStory?: any | null };

export const GetStoryblokStoryDocument = gql`
  query GetStoryblokStory(
    $slug: String
    $position: String
    $name: String
    $locale: String
  ) {
    storyblokStory(
      slug: $slug
      position: $position
      name: $name
      locale: $locale
    )
  }
`;
export type GetStoryblokStoryQueryResult = Apollo.QueryResult<
  GetStoryblokStoryQuery,
  GetStoryblokStoryQueryVariables
>;
