import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { paymentMethods } from "../../lib/data/payment";
import { usePayment } from "@precomposer/data";

type Props = {
  setStep: Dispatch<SetStateAction<number>>;
};

const CheckoutPaymentForm: FC<Props> = ({ setStep }) => {
  const { t } = useTranslation(["checkout"]);
  const { paymentMethod, setPaymentMethodAction } = usePayment();

  useEffect(() => {
    if (paymentMethod) setStep(3);
  }, [setStep, paymentMethod]);

  function handleChange(payment: string | undefined) {
    setPaymentMethodAction(payment);
    setStep(3);
  }

  return (
    <form>
      <fieldset className="mt-6">
        <legend className={"pb-3"}>{t("checkout:payment.legend")}</legend>
        <ul className="w-full">
          {paymentMethods.map((option, index) => (
            <li key={index} className={"my-2"}>
              <input
                id={option.id}
                name="payment-method"
                type="radio"
                value={option.value}
                className="peer hidden"
                checked={paymentMethod === option.value}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
              />
              <label
                htmlFor={option.id}
                className="inline-flex w-full cursor-pointer items-center justify-between rounded-lg border border-gray-200 bg-white p-5 text-gray-500 hover:bg-gray-100 hover:text-gray-600 peer-checked:border-blue-500 peer-checked:text-blue-500"
              >
                <div className="block">
                  <div className="w-full text-lg font-bold">{option.label}</div>
                </div>
              </label>
            </li>
          ))}
        </ul>
      </fieldset>
    </form>
  );
};

export default CheckoutPaymentForm;
