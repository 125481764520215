import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { cookie } from "../lib/cookie";
import { useSession } from "next-auth/react";
import isString from "lodash/isString";

import { Cart, CartUpdateAction } from "../graphql/types.generated";
import {
  UpdateCartDocument,
  UpdateCartMutation,
  UpdateCartMutationVariables,
} from "../graphql/cart/UpdateCart.mutation.generated";
import { env } from "@precomposer/env";

type UseUpdateCart = {
  updateCart: (action: Array<CartUpdateAction>) => Promise<Cart | null>;
};

export const useUpdateCart = (): UseUpdateCart => {
  const client = useApolloClient();
  const { data: session } = useSession();
  const { getCookie, setCookie } = cookie({ key: "cartId" });
  const cartExpiration = 24 * 60 * 60 * 2400;

  const initialize = useCallback(
    (id: string): void => {
      const cartId = getCookie();
      if (session || (isString(cartId) && cartId !== "")) {
        return;
      }
      setCookie(id, {
        sameSite: "lax",
        expires: new Date(new Date().getTime() + cartExpiration),
      });
    },
    [getCookie, setCookie, session]
  );

  const update = useCallback(
    async (action: Array<CartUpdateAction>): Promise<Cart | null> => {
      const { data } = await client.mutate<
        UpdateCartMutation,
        UpdateCartMutationVariables
      >({
        mutation: UpdateCartDocument,
        variables: {
          actions: action,
          locale: env("DEFAULT_LOCALE"),
          currencyCode: env("DEFAULT_CURRENCY"),
        },
      });
      if (data?.batchUpdateMyCart) {
        initialize(data.batchUpdateMyCart.id);
        return data.batchUpdateMyCart;
      }
      return null;
    },
    [client, initialize]
  );

  return {
    updateCart: update,
  };
};
