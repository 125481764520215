import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { CartFragmentDoc } from "./Cart.fragment.generated";
import * as Apollo from "@apollo/client";
export type UpdateCartMutationVariables = Types.Exact<{
  actions:
    | Array<Types.InputMaybe<Types.CartUpdateAction>>
    | Types.InputMaybe<Types.CartUpdateAction>;
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
  currencyCode?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type UpdateCartMutation = {
  batchUpdateMyCart?: {
    id: string;
    cartState?: string | null;
    customerId?: string | null;
    customerEmail?: string | null;
    version: number;
    totalNetPriceInCent: number;
    totalGrossPriceInCent: number;
    currencyCode?: string | null;
    taxes?: Array<{ amount: number; name: string; rate: number }> | null;
    billingAddress?: {
      city: string;
      company?: string | null;
      country: string;
      firstName: string;
      lastName: string;
      postalCode: string;
      streetName: string;
      streetNumber: string;
    } | null;
    shippingAddress?: {
      city: string;
      company?: string | null;
      country: string;
      firstName: string;
      lastName: string;
      postalCode: string;
      streetName: string;
      streetNumber: string;
    } | null;
    cartItems: Array<{
      id: string;
      quantity: number;
      productId: string;
      productName: string;
      productSlug: string;
      sku: string;
      priceInCent: number;
      totalNetPriceInCent: number;
      totalGrossPriceInCent: number;
      images?: Array<{
        alt: string;
        height: string;
        title?: string | null;
        url: string;
        width: string;
      }> | null;
    }>;
  } | null;
};

export const UpdateCartDocument = gql`
  mutation UpdateCart(
    $actions: [CartUpdateAction]!
    $locale: String
    $currencyCode: String
  ) {
    batchUpdateMyCart(
      actions: $actions
      locale: $locale
      currencyCode: $currencyCode
    ) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type UpdateCartMutationFn = Apollo.MutationFunction<
  UpdateCartMutation,
  UpdateCartMutationVariables
>;
export type UpdateCartMutationResult =
  Apollo.MutationResult<UpdateCartMutation>;
export type UpdateCartMutationOptions = Apollo.BaseMutationOptions<
  UpdateCartMutation,
  UpdateCartMutationVariables
>;
