import { CookieSerializeOptions, serialize } from "cookie";

type TmpCookiesObj =
  | { [key: string]: string }
  | Partial<{ [key: string]: string }>;

type CookieValueTypes = string | boolean | undefined | null;

type Cookie = {
  getCookie: () => CookieValueTypes;
  setCookie: (data: any, options?: CookieSerializeOptions) => void;
  deleteCookie: (options?: CookieSerializeOptions) => void;
};

type Params = {
  key: string;
};

export const cookie = ({ key }: Params): Cookie => {
  const processValue = (value: string): CookieValueTypes => {
    if (value === "true") return true;
    if (value === "false") return false;
    if (value === "undefined") return undefined;
    if (value === "null") return null;
    return value;
  };

  const stringify = (value: string = "") => {
    try {
      const result = JSON.stringify(value);
      return /^[\{\[]/.test(result) ? result : value;
    } catch (e) {
      return value;
    }
  };

  const decode = (str: string): string => {
    if (!str) {
      return str;
    }
    return str.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
  };

  const getCookie = (): CookieValueTypes => {
    const cookies: TmpCookiesObj = {};
    const documentCookies = document.cookie ? document.cookie.split("; ") : [];
    for (let i = 0, len = documentCookies.length; i < len; i++) {
      const cookieParts = documentCookies[i].split("=");
      const cookie = cookieParts.slice(1).join("=");
      const name = cookieParts[0];

      cookies[name] = cookie;
    }
    const value = cookies[key];
    if (value === undefined) {
      return undefined;
    }
    return processValue(decode(value));
  };

  const setCookie = (data: any, options?: CookieSerializeOptions): void => {
    let cookieOptions: any;
    if (options) {
      cookieOptions = options;
    }
    document.cookie = serialize(key, stringify(data), {
      path: "/",
      ...cookieOptions,
    });
  };

  const deleteCookie = (options?: CookieSerializeOptions): void => {
    return setCookie("", { ...options, maxAge: -1 });
  };

  return {
    getCookie,
    deleteCookie,
    setCookie,
  };
};
