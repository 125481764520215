import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
export type CategoryTreeItemFragment = {
  category: { id: string; name: string; slug: string };
};

export const CategoryTreeItemFragmentDoc = gql`
  fragment CategoryTreeItem on CategoryNode {
    category {
      id
      name
      slug
    }
  }
`;
