import React, { FC, ReactElement, ReactNode } from "react";
import { ApolloProvider } from "@apollo/client";
import { getClient } from "./lib/client";
import DataProvider from "./provider/DataProvider";
import { DataContext } from "./provider/DataContext";

type Props = {
  children: ReactNode | ReactElement;
  token?: string | null;
};

const PrecomposerData: FC<Props> = ({ children, token }) => {
  return (
    <ApolloProvider client={getClient(token)}>
      <DataProvider context={DataContext}>{children}</DataProvider>
    </ApolloProvider>
  );
};

export default PrecomposerData;
export * from "./graphql/types.generated";
export { useCart, type UseCart } from "./provider/UseCart";
export { useCustomer, type UseCustomer } from "./provider/UseCustomer";
export { usePayment, type UsePayment } from "./provider/UsePayment";
export { useGetOrderList } from "./hooks/useGetOrderList";

// Interim solution to provide data for next.js SSR
// @todo Change or remove when moving to next.js App Router CTPT-423
export * from "./lib/client";
export * from "./graphql/cart/GetCart.query.generated";
export * from "./graphql/cart/UpdateCart.mutation.generated";
export * from "./graphql/customer/SignInCustomer.query.generated";
export * from "./graphql/customer/SignOutCustomer.query.generated";
export * from "./graphql/cms/GetPage.query.generated";
export * from "./graphql/catalog/category/GetCategory.query.generated";
export * from "./graphql/catalog/category/GetCategoryTree.query.generated";
export * from "./graphql/catalog/product-list/GetProductList.query.generated";
export * from "./graphql/catalog/product/GetProduct.query.generated";
export * from "./graphql/sitemap/GetCategorySitemap.query.generated";
export * from "./graphql/sitemap/GetCmsSitemap.query.generated";
export * from "./graphql/sitemap/GetProductSitemap.query.generated";
export * from "./graphql/order/GetOrderList.query.generated";
export * from "./graphql/cms/GetStoryblokStory.query.generated";
