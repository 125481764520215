import { storyblokEditable } from "@storyblok/react";
import React from "react";
import CheckoutStepsCMS from "../../cms/checkout/CheckoutStepsCMS";

const StoryblokCheckoutSteps = ({ blok, ...restProps }) => {
  const step: number = restProps.step;

  return (
    <div {...storyblokEditable(blok)}>
      <CheckoutStepsCMS step={step} />
    </div>
  );
};

export default StoryblokCheckoutSteps;
