import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
export type ProductVariantFragment = {
  id: string;
  sku: string;
  isMaster: boolean;
  isMatching: boolean;
  attributesRaw: Array<{ name: string; value: any }>;
  prices: Array<{
    centAmount: number;
    discountedCentAmount?: number | null;
    taxType: string;
  }>;
};

export const ProductVariantFragmentDoc = gql`
  fragment ProductVariant on ProductVariant {
    attributesRaw {
      ... on ProductAttribute {
        name
        value
      }
    }
    id
    sku
    isMaster
    isMatching
    prices {
      ... on Price {
        centAmount
        discountedCentAmount
        taxType
      }
    }
  }
`;
