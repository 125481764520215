import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";

import { Cart } from "../graphql/types.generated";
import {
  GetCartDocument,
  GetCartQuery,
  GetCartQueryVariables,
} from "../graphql/cart/GetCart.query.generated";
import { env } from "@precomposer/env";

type UseGetCart = {
  getCart: () => Promise<Cart>;
};

export const useGetCart = (): UseGetCart => {
  const client = useApolloClient();

  const get = useCallback(async (): Promise<Cart> => {
    const { data, errors } = await client.query<
      GetCartQuery,
      GetCartQueryVariables
    >({
      query: GetCartDocument,
      variables: {
        currencyCode: env("DEFAULT_CURRENCY"),
        locale: env("DEFAULT_LOCALE"),
      },
    });
    if (!data?.myCart) {
      throw new Error("Failed to get the cart data");
    }
    return data.myCart;
  }, [client]);

  return {
    getCart: get,
  };
};
