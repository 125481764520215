import Image, { ImageProps } from "../Image";
import React from "react";

export default function NotFoundPageContent({
  image,
  fontSize,
  fontFamily,
  text,
}) {
  let imageProps: ImageProps = image;

  return (
    <>
      <div>
        <Image alt={image.alt} {...imageProps} />
      </div>
      <div className={`pt-6 text-center ${fontSize} ${fontFamily}`}>
        <p>{text}</p>
      </div>
    </>
  );
}
