import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { CartFragmentDoc } from "./Cart.fragment.generated";
import * as Apollo from "@apollo/client";
export type GetCartQueryVariables = Types.Exact<{
  currencyCode?: Types.InputMaybe<Types.Scalars["String"]>;
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetCartQuery = {
  myCart?: {
    id: string;
    cartState?: string | null;
    customerId?: string | null;
    customerEmail?: string | null;
    version: number;
    totalNetPriceInCent: number;
    totalGrossPriceInCent: number;
    currencyCode?: string | null;
    taxes?: Array<{ amount: number; name: string; rate: number }> | null;
    billingAddress?: {
      city: string;
      company?: string | null;
      country: string;
      firstName: string;
      lastName: string;
      postalCode: string;
      streetName: string;
      streetNumber: string;
    } | null;
    shippingAddress?: {
      city: string;
      company?: string | null;
      country: string;
      firstName: string;
      lastName: string;
      postalCode: string;
      streetName: string;
      streetNumber: string;
    } | null;
    cartItems: Array<{
      id: string;
      quantity: number;
      productId: string;
      productName: string;
      productSlug: string;
      sku: string;
      priceInCent: number;
      totalNetPriceInCent: number;
      totalGrossPriceInCent: number;
      images?: Array<{
        alt: string;
        height: string;
        title?: string | null;
        url: string;
        width: string;
      }> | null;
    }>;
  } | null;
};

export const GetCartDocument = gql`
  query GetCart($currencyCode: String, $locale: String) {
    myCart(currencyCode: $currencyCode, locale: $locale) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type GetCartQueryResult = Apollo.QueryResult<
  GetCartQuery,
  GetCartQueryVariables
>;
