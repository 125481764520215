import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type GetPageQueryVariables = Types.Exact<{
  slug?: Types.InputMaybe<Types.Scalars["String"]>;
  position?: Types.InputMaybe<Types.Scalars["String"]>;
  name?: Types.InputMaybe<Types.Scalars["String"]>;
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetPageQuery = { pageContent?: any | null };

export const GetPageDocument = gql`
  query GetPage(
    $slug: String
    $position: String
    $name: String
    $locale: String
  ) {
    pageContent(slug: $slug, position: $position, name: $name, locale: $locale)
  }
`;
export type GetPageQueryResult = Apollo.QueryResult<
  GetPageQuery,
  GetPageQueryVariables
>;
