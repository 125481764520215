import React, { ReactNode, useState } from "react";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { getAllCounties } from "../../lib/data/countries";
import { useTranslation } from "next-i18next";
import { Address, useCustomer } from "@precomposer/data";
import { Button, InputText, Select } from "@precomposer/ui";

const accountShippingAddressSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "account:address.form.shipping.msg.first-name"
    )
    .max(40)
    .required("account:address.form.shipping.error.first-name"),
  lastName: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "account:address.form.shipping.msg.last-name"
    )
    .max(40)
    .required("account:address.form.shipping.error.last-name"),
  streetName: Yup.string().required(
    "account:address.form.shipping.error.street-name"
  ),
  streetNumber: Yup.number()
    .typeError("account:address.form.shipping.msg.street-number")
    .required("account:address.form.shipping.error.street-number"),
  postalCode: Yup.number()
    .typeError("account:address.form.shipping.msg.postal-code")
    .required("account:address.form.shipping.error.postal-code"),
  city: Yup.string().required("account:address.form.shipping.error.city"),
  country: Yup.string().required("account:address.form.shipping.error.country"),
});

const accountShippingAddressFields: Address = {
  firstName: "",
  lastName: "",
  streetName: "",
  streetNumber: "",
  postalCode: "",
  city: "",
  country: "",
  key: "",
};

const AccountShippingAddressForm = () => {
  const { t } = useTranslation(["account"]);
  const [message, setMessage] = useState<ReactNode | null>(null);
  const { customerData, setCustomerShippingAddressAction } = useCustomer();
  const { errors, values, touched, handleChange, handleSubmit, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues:
        (customerData.addresses.find(
          ({ type }) => type === "shippingAddress"
        ) as Address) || accountShippingAddressFields,
      validationSchema: accountShippingAddressSchema,
      onSubmit: submitForm,
    });

  async function submitForm(
    values: Address,
    { setSubmitting }: FormikHelpers<Address>
  ): Promise<void> {
    const response = await setCustomerShippingAddressAction({
      address: values,
    });
    setSubmitting(false);
    if (!response) {
      setMessage(
        <div className={"my-2 text-red-700"}>
          {t("account:address.form.shipping.error.response")}
        </div>
      );
    } else {
      setMessage(
        <div className={"my-2 text-green-700"}>
          {t("account:address.form.shipping.msg.response")}
        </div>
      );
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={"mb-6"}
      key={"accountShippingAddressForm"}
    >
      <fieldset className={"mb-4 grid gap-4 md:grid-cols-12"}>
        <legend className={"pb-3"}>
          {t("account:address.form.shipping.legend")}
        </legend>
        <input type={"hidden"} name={"key"} value={values.key || ""} />
        <div className={"col-span-12 lg:col-span-6"}>
          <InputText
            type={"text"}
            id={"shipping-address-first-name"}
            name={"firstName"}
            label={t("account:address.form.shipping.label.first-name")}
            fullWidth
            value={values.firstName}
            onChange={handleChange}
            error={touched.firstName && Boolean(errors.firstName)}
            message={
              touched.firstName &&
              errors.firstName !== undefined &&
              t(`${errors.firstName}` as unknown as TemplateStringsArray)
            }
          />
        </div>
        <div className={"col-span-12 lg:col-span-6"}>
          <InputText
            type={"text"}
            id={"shipping-address-last-name"}
            name={"lastName"}
            label={t("account:address.form.shipping.label.last-name")}
            fullWidth
            value={values.lastName}
            onChange={handleChange}
            error={touched.lastName && Boolean(errors.lastName)}
            message={
              touched.lastName &&
              errors.lastName !== undefined &&
              t(`${errors.lastName}` as unknown as TemplateStringsArray)
            }
          />
        </div>
        <div className={"col-span-12 lg:col-span-10"}>
          <InputText
            type={"text"}
            id={"shipping-address-street-name"}
            name={"streetName"}
            label={t("account:address.form.shipping.label.street-name")}
            fullWidth
            value={values.streetName}
            onChange={handleChange}
            error={touched.streetName && Boolean(errors.streetName)}
            message={
              touched.streetName &&
              errors.streetName !== undefined &&
              t(`${errors.streetName}` as unknown as TemplateStringsArray)
            }
          />
        </div>
        <div className={"col-span-12 lg:col-span-2"}>
          <InputText
            type={"text"}
            id={"shipping-address-street-number"}
            name={"streetNumber"}
            label={t("account:address.form.shipping.label.street-number")}
            fullWidth
            value={values.streetNumber}
            onChange={handleChange}
            error={touched.streetNumber && Boolean(errors.streetNumber)}
            message={
              touched.streetNumber &&
              errors.streetNumber !== undefined &&
              t(`${errors.streetNumber}` as unknown as TemplateStringsArray)
            }
          />
        </div>
        <div className={"col-span-12 lg:col-span-4"}>
          <InputText
            type={"text"}
            id={"shipping-address-postal-code"}
            name={"postalCode"}
            label={t("account:address.form.shipping.label.postal-code")}
            fullWidth
            value={values.postalCode}
            onChange={handleChange}
            error={touched.postalCode && Boolean(errors.postalCode)}
            message={
              touched.postalCode &&
              errors.postalCode !== undefined &&
              t(`${errors.postalCode}` as unknown as TemplateStringsArray)
            }
          />
        </div>
        <div className={"col-span-12 lg:col-span-8"}>
          <InputText
            type={"text"}
            id={"shipping-address-city"}
            name={"city"}
            label={t("account:address.form.shipping.label.city")}
            fullWidth
            value={values.city}
            onChange={handleChange}
            error={touched.city && Boolean(errors.city)}
            message={
              touched.city &&
              errors.city !== undefined &&
              t(`${errors.city}` as unknown as TemplateStringsArray)
            }
          />
        </div>
        <div className={"col-span-12"}>
          <Select
            id={"shipping-address-country"}
            name={"country"}
            label={t("account:address.form.shipping.label.country")}
            fullWidth
            value={values.country}
            onChange={handleChange}
            error={touched.country && Boolean(errors.country)}
            message={
              touched.country &&
              errors.country !== undefined &&
              t(`${errors.country}` as unknown as TemplateStringsArray)
            }
          >
            <Select.Option>
              {t("account:address.form.shipping.select")}
            </Select.Option>
            {getAllCounties().map(([key, value]) => {
              return (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </fieldset>
      <Button type={"submit"} variant={"primary"} disabled={isSubmitting}>
        {t("account:address.form.submit")}
      </Button>
      {message}
    </form>
  );
};

export default AccountShippingAddressForm;
