import React, { FC } from "react";
import { Product } from "@precomposer/data";

type Props = {
  product: Product;
};

const ProductDescription: FC<Props> = ({ product }) => {
  return <>{product.description && <p>{product.description}</p>}</>;
};

export default ProductDescription;
