import React, { FC } from "react";
import NextImage, { ImageLoader } from "next/image";

export type ImageProps = {
  filename: string;
  alt: string;
  width?: number;
  height?: number;
  id?: number;
  name?: string;
  focus?: string;
  title?: string;
  copyright?: string;
  fieldtype?: string;
  is_external_url?: boolean;
};

type UseImageSize = {
  width: number;
  height: number;
};

/**
 * Extract the width and height of one image URL (filename) provided by a Storyblok asset URL. See https://www.storyblok.com/faq/image-dimensions-assets-js
 */
function useImageSize(url: string): UseImageSize {
  let width: number = 0;
  let height: number = 0;
  const dimension = url.split("/")[5].split("x");
  if (dimension[0] && dimension[1]) {
    width = parseInt(dimension[0]);
    height = parseInt(dimension[1]);
  }
  return {
    width,
    height,
  };
}

const storyblokImageLoader: ImageLoader = ({ src, quality, width }) => {
  const widthAndHeight = `${width}x0`;
  const imageModifications = `filters:quality(${quality ?? 100})`;

  return `${src}/m/${widthAndHeight}/${imageModifications}`;
};

const Image: FC<ImageProps> = ({
  filename,
  alt,
  width,
  height,
  id,
  name,
  focus,
  title,
  copyright,
  fieldtype,
  is_external_url,
}) => {
  const { width: fileWidth, height: fileHeight } = useImageSize(filename);

  return (
    <>
      {is_external_url ? (
        <div>
          It is not allowed to use an external URL as source for an image!
        </div>
      ) : (
        <NextImage
          key={id}
          alt={alt}
          src={filename}
          // todo: Activation of a custom image loader for cropping images according to specific properties.
          //loader={storyblokImageLoader}
          width={width || fileWidth}
          height={height || fileHeight}
          className={"h-auto max-w-full"}
        />
      )}
    </>
  );
};

export default Image;
