import React from "react";
import { useTranslation } from "next-i18next";
import { Button, Heading } from "@precomposer/ui";

const CheckoutSuccess = () => {
  const { t } = useTranslation(["checkout", "common"]);

  return (
    <div className={"mt-4"}>
      <Heading element={"h2"} className={"mb-4"}>
        {t("checkout:success.heading")}
      </Heading>
      <p className={"mb-4"}>{t("checkout:success.text")}</p>
      <Button variant={"secondary"} href={"/"}>
        {t("common:home.title")}
      </Button>
    </div>
  );
};

export default CheckoutSuccess;
