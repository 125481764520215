import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import Column from "../../cms/Column";

const StoryblokColumn = ({ blok }) => {
  return (
    <Column {...storyblokEditable(blok)} colspan={blok.colspan}>
      {blok.children.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </Column>
  );
};

export default StoryblokColumn;
