import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type GetProductSitemapQueryVariables = Types.Exact<{
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
  page?: Types.InputMaybe<Types.Scalars["Int"]>;
  perPage?: Types.InputMaybe<Types.Scalars["Int"]>;
  currencyCode?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetProductSitemapQuery = {
  productList?: {
    products?: Array<{ slug?: string | null }> | null;
    metadata: { count: number; offset: number; total: number };
  } | null;
};

export const GetProductSitemapDocument = gql`
  query GetProductSitemap(
    $locale: String
    $page: Int
    $perPage: Int
    $currencyCode: String
  ) {
    productList(
      filter: {}
      locale: $locale
      page: $page
      perPage: $perPage
      currencyCode: $currencyCode
    ) {
      products {
        slug
      }
      metadata {
        count
        offset
        total
      }
    }
  }
`;
export type GetProductSitemapQueryResult = Apollo.QueryResult<
  GetProductSitemapQuery,
  GetProductSitemapQueryVariables
>;
