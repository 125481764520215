import React from "react";
import { Heading } from "@precomposer/ui";
import CheckoutButton from "../../checkout/CheckoutButton";
import ProductPrice from "../../product/ProductPrice";
import { Cart } from "@precomposer/data";

type TextProps = {
  heading: string;
  item: string;
  subtotal: string;
  total: string;
};

type CmsCartSummaryProps = {
  cartData: Cart;
} & TextProps;

const CmsCartSummary: React.FC<CmsCartSummaryProps> = ({
  cartData,
  heading,
  item,
  subtotal,
  total,
}) => {
  return (
    <>
      <Heading element={"h2"} className={"mb-3 text-dynamic"}>
        {heading}
        <span
          className={
            "ml-2 whitespace-nowrap text-base font-bold lg:ml-0 lg:block"
          }
        >
          ({cartData.cartItems.length} {item})
        </span>
      </Heading>
      <dl className={"mb-3"}>
        <div className={"flex py-3"}>
          <dt className={"flex-1"}>{subtotal}</dt>
          <dd>
            <ProductPrice centAmount={cartData.totalNetPriceInCent} />
          </dd>
        </div>
        {cartData.taxes?.map((tax, index) => {
          return (
            <div key={index} className={"flex py-3"}>
              <dt className={"flex-1"}>{tax.name}</dt>
              <dd>
                <ProductPrice centAmount={tax.amount} />
              </dd>
            </div>
          );
        })}
        <div className={"flex border-t py-3 font-bold"}>
          <dt className={"flex-1"}>{total}</dt>
          <dd>
            <ProductPrice centAmount={cartData.totalGrossPriceInCent} />
          </dd>
        </div>
      </dl>
      <CheckoutButton />
    </>
  );
};

export default CmsCartSummary;
