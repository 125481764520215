import React, { useContext } from "react";
import { DataContext } from "./DataContext";
import {
  Customer,
  SignUpCustomerData,
  UpdateAddressAction,
} from "../graphql/types.generated";

export type UseCustomer = {
  customerData: Customer;
  setCustomerBillingAddressAction: ({
    address,
  }: UpdateAddressAction) => Promise<boolean>;
  setCustomerShippingAddressAction: ({
    address,
  }: UpdateAddressAction) => Promise<boolean>;
  signUpCustomerAction: ({
    email,
    firstName,
    lastName,
    password,
  }: SignUpCustomerData) => Promise<boolean>;
};

const defaultCustomerData: Customer = {
  addresses: [],
  email: "",
  id: "",
  version: 0,
};

const defaultCustomerAction = async () => {
  return false;
};

export const defaultUseCustomer: UseCustomer = {
  customerData: defaultCustomerData,
  setCustomerBillingAddressAction: defaultCustomerAction,
  setCustomerShippingAddressAction: defaultCustomerAction,
  signUpCustomerAction: defaultCustomerAction,
};

export const useCustomer = (): UseCustomer => {
  const context = useContext(DataContext);
  if (!context.customer)
    throw new Error("Expected to be wrapped in a PrecomposerData Component");
  return context.customer;
};
