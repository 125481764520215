import React, { Dispatch, FC, ReactElement, SetStateAction } from "react";
import { useTranslation } from "next-i18next";
import { CartAddress, Maybe, useCart } from "@precomposer/data";
import { getCountry } from "../../lib/data/countries";
import { Button } from "@precomposer/ui";
import { PencilIcon } from "@heroicons/react/24/outline";

type Props = {
  setStep: Dispatch<SetStateAction<number>>;
};

const CheckoutCartAddressInfo: FC<Props> = ({ setStep }) => {
  const { t } = useTranslation(["checkout"]);
  const { cartData } = useCart();

  const CartAddress = ({
    address,
  }: {
    address?: Maybe<CartAddress>;
  }): ReactElement => {
    return (
      <>
        {address && (
          <ul>
            <li>
              {address.firstName} {address.lastName}
            </li>
            <li>
              {address.streetName} {address.streetNumber}
            </li>
            <li>
              {address.postalCode} {address.city}
            </li>
            <li>{getCountry(address.country)}</li>
          </ul>
        )}
      </>
    );
  };

  return (
    <div className={"my-6 py-4"}>
      <div className={"grid gap-4 md:grid-cols-2"}>
        <div>
          <div className={"flex"}>
            <h3 className={"flex-1 text-lg"}>
              {t("checkout:payment.heading-billing")}
            </h3>
            <Button onClick={() => setStep(1)}>
              <span className={"sr-only"}>
                {t("checkout:payment.edit-address")}
              </span>
              <PencilIcon className={"inline-block h-5 w-5"} />
            </Button>
          </div>
          <CartAddress address={cartData.billingAddress} />
        </div>
        <div>
          <div className={"flex"}>
            <h3 className={"flex-1 text-lg"}>
              {t("checkout:payment.heading-shipping")}
            </h3>
            <Button onClick={() => setStep(1)}>
              <span className={"sr-only"}>
                {t("checkout:payment.edit-address")}
              </span>
              <PencilIcon className={"ml-4 inline-block h-5 w-5"} />
            </Button>
          </div>
          <CartAddress address={cartData.shippingAddress} />
        </div>
      </div>
    </div>
  );
};

export default CheckoutCartAddressInfo;
