/**
 * Note: An intermediate solution to provide a list of available payment methods.
 * @todo Get the data from Aggregator backend
 */

export interface PaymentMethods {
  id?: string;
  label?: string;
  value?: string;
}

export const paymentMethods = [
  {
    id: "invoice",
    label: "Invoice",
    value: "invoice",
  },
  {
    id: "money-order",
    label: "Money order",
    value: "money-order",
  },
];
