import React, { FC } from "react";
import CheckoutAddressForm from "../../checkout/CheckoutAddressForm";
import CheckoutCartAddressInfo from "../../checkout/CheckoutCartAddressInfo";
import CheckoutPaymentForm from "../../checkout/CheckoutPaymentForm";
import CheckoutConfirmationForm from "../../checkout/CheckoutConfirmationForm";
import CheckoutSuccess from "../../checkout/CheckoutSuccess";
import EmptyCart from "../../cart/EmptyCart";

type Props = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const CheckoutStepContent: FC<Props> = ({ step, setStep }) => {
  let stepContent = null;

  switch (step) {
    case 0:
      stepContent = <EmptyCart />;
      break;
    case 1:
      stepContent = <CheckoutAddressForm setStep={setStep} />;
      break;
    case 2:
    case 3:
      stepContent = (
        <>
          <CheckoutCartAddressInfo setStep={setStep} />
          <CheckoutPaymentForm setStep={setStep} />
          <CheckoutConfirmationForm setStep={setStep} />
        </>
      );
      break;
    case 4:
      stepContent = <CheckoutSuccess />;
      break;
    default:
      stepContent = <div></div>;
      break;
  }

  return <>{stepContent}</>;
};

export default CheckoutStepContent;
