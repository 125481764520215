import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { CustomerAddressFragmentDoc } from "./CustomerAddress.fragment.generated";
export type CustomerFragment = {
  id: string;
  version: number;
  email: string;
  isEmailVerified?: boolean | null;
  token?: string | null;
  defaultBillingAddressKey?: string | null;
  defaultShippingAddressKey?: string | null;
  addresses: Array<{
    key?: string | null;
    type?: string | null;
    streetName?: string | null;
    salutation?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    streetNumber?: string | null;
    postalCode?: string | null;
    city?: string | null;
    country?: string | null;
    company?: string | null;
  }>;
};

export const CustomerFragmentDoc = gql`
  fragment Customer on Customer {
    id
    version
    email
    isEmailVerified
    token
    addresses {
      ...CustomerAddress
    }
    defaultBillingAddressKey
    defaultShippingAddressKey
  }
  ${CustomerAddressFragmentDoc}
`;
