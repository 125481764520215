import React from "react";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { useTranslation } from "next-i18next";
import { getAllCounties } from "../../lib/data/countries";
import { SetShippingAddressAction } from "@precomposer/data";
import { InputText, Select } from "@precomposer/ui";

export const checkoutShippingAddressSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "checkout:addresses.form.shipping.msg.first-name"
    )
    .max(40)
    .required("checkout:addresses.form.shipping.error.first-name"),
  lastName: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "checkout:addresses.form.shipping.msg.last-name"
    )
    .max(40)
    .required("checkout:addresses.form.shipping.error.last-name"),
  streetName: Yup.string().required(
    "checkout:addresses.form.shipping.error.street-name"
  ),
  streetNumber: Yup.number()
    .typeError("checkout:addresses.form.shipping.msg.street-number")
    .required("checkout:addresses.form.shipping.error.street-number"),
  postalCode: Yup.number()
    .typeError("checkout:addresses.form.shipping.msg.postal-code")
    .required("checkout:addresses.form.shipping.error.postal-code"),
  city: Yup.string().required("checkout:addresses.form.shipping.error.city"),
  country: Yup.string().required(
    "checkout:addresses.form.shipping.error.country"
  ),
});

export const checkoutShippingAddressFields: SetShippingAddressAction = {
  shippingAddress: {
    firstName: "",
    lastName: "",
    streetName: "",
    streetNumber: "",
    postalCode: "",
    city: "",
    country: "",
  },
};

export const CheckoutShippingAddressFieldset = () => {
  const { t } = useTranslation(["checkout"]);
  const { values, touched, errors, handleChange } =
    useFormikContext<typeof checkoutShippingAddressFields>();

  return (
    <fieldset className={"mb-4 grid gap-4 md:grid-cols-12"}>
      <legend className={"pb-3"}>
        {t("checkout:addresses.form.shipping.legend")}
      </legend>
      <div className={"col-span-12 lg:col-span-6"}>
        <InputText
          type={"text"}
          id={"shipping-address-first-name"}
          name={"shippingAddress[firstName]"}
          label={t("checkout:addresses.form.shipping.label.first-name")}
          fullWidth
          value={values.shippingAddress.firstName}
          onChange={handleChange}
          error={
            touched.shippingAddress?.firstName &&
            Boolean(errors.shippingAddress?.firstName)
          }
          message={
            touched.shippingAddress?.firstName &&
            errors.shippingAddress?.firstName !== undefined &&
            t(
              `${errors.shippingAddress?.firstName}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12 lg:col-span-6"}>
        <InputText
          type={"text"}
          id={"shipping-address-last-name"}
          name={"shippingAddress[lastName]"}
          label={t("checkout:addresses.form.shipping.label.last-name")}
          fullWidth
          value={values.shippingAddress.lastName}
          onChange={handleChange}
          error={
            touched.shippingAddress?.lastName &&
            Boolean(errors.shippingAddress?.lastName)
          }
          message={
            touched.shippingAddress?.lastName &&
            errors.shippingAddress?.lastName !== undefined &&
            t(
              `${errors.shippingAddress?.lastName}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12 lg:col-span-10"}>
        <InputText
          type={"text"}
          id={"shipping-address-street-name"}
          name={"shippingAddress[streetName]"}
          label={t("checkout:addresses.form.shipping.label.street-name")}
          fullWidth
          value={values.shippingAddress.streetName}
          onChange={handleChange}
          error={
            touched.shippingAddress?.streetName &&
            Boolean(errors.shippingAddress?.streetName)
          }
          message={
            touched.shippingAddress?.streetName &&
            errors.shippingAddress?.streetName !== undefined &&
            t(
              `${errors.shippingAddress?.streetName}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12 lg:col-span-2"}>
        <InputText
          type={"text"}
          id={"shipping-address-street-number"}
          name={"shippingAddress[streetNumber]"}
          label={t("checkout:addresses.form.shipping.label.street-number")}
          fullWidth
          value={values.shippingAddress.streetNumber}
          onChange={handleChange}
          error={
            touched.shippingAddress?.streetNumber &&
            Boolean(errors.shippingAddress?.streetNumber)
          }
          message={
            touched.shippingAddress?.streetNumber &&
            errors.shippingAddress?.streetNumber !== undefined &&
            t(
              `${errors.shippingAddress?.streetNumber}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12 lg:col-span-4"}>
        <InputText
          type={"text"}
          id={"shipping-address-postal-code"}
          name={"shippingAddress[postalCode]"}
          label={t("checkout:addresses.form.shipping.label.postal-code")}
          fullWidth
          value={values.shippingAddress.postalCode}
          onChange={handleChange}
          error={
            touched.shippingAddress?.postalCode &&
            Boolean(errors.shippingAddress?.postalCode)
          }
          message={
            touched.shippingAddress?.postalCode &&
            errors.shippingAddress?.postalCode !== undefined &&
            t(
              `${errors.shippingAddress?.postalCode}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12 lg:col-span-8"}>
        <InputText
          type={"text"}
          id={"shipping-address-city"}
          name={"shippingAddress[city]"}
          label={t("checkout:addresses.form.shipping.label.city")}
          fullWidth
          value={values.shippingAddress.city}
          onChange={handleChange}
          error={
            touched.shippingAddress?.city &&
            Boolean(errors.shippingAddress?.city)
          }
          message={
            touched.shippingAddress?.city &&
            errors.shippingAddress?.city !== undefined &&
            t(
              `${errors.shippingAddress?.city}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12"}>
        <Select
          id={"shipping-address-country"}
          name={"shippingAddress[country]"}
          label={t("checkout:addresses.form.shipping.label.country")}
          fullWidth
          value={values.shippingAddress.country}
          onChange={handleChange}
          error={
            touched.shippingAddress?.country &&
            Boolean(errors.shippingAddress?.country)
          }
          message={
            touched.shippingAddress?.country &&
            errors.shippingAddress?.country !== undefined &&
            t(
              `${errors.shippingAddress?.country}` as unknown as TemplateStringsArray
            )
          }
        >
          <Select.Option>
            {t("checkout:addresses.form.shipping.select")}
          </Select.Option>
          {getAllCounties().map(([key, value]) => {
            return (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </fieldset>
  );
};
