import StoryblokTeaser from "../components/storyblok/home-page/StoryblokTeaser";
import StoryblokPage from "../components/storyblok/home-page/StoryblokPage";
import StoryblokBanner from "../components/storyblok/home-page/StoryblokBanner";
import StoryblokColumn from "../components/storyblok/home-page/StoryblokColumn";
import StoryblokGrid from "../components/storyblok/home-page/StoryblokGrid";
import StoryblokProductDetailPage from "../components/storyblok/product-detail/StoryblokProductDetailPage";
import StoryblokInnerFlexContainer from "../components/storyblok/product-detail/StoryblokInnerFlexContainer";
import StoryblokOuterFlexContainer from "../components/storyblok/product-detail/StoryblokOuterFlexContainer";
import StoryblokProductDescription from "../components/storyblok/product-detail/StoryblokProductDescription";
import StoryblokProductImage from "../components/storyblok/product-detail/StoryblokProductImage";
import StoryblokProductName from "../components/storyblok/product-detail/StoryblokProductName";
import StoryblokProductPrice from "../components/storyblok/product-detail/StoryblokProductPrice";
import StoryblokProductVariant from "../components/storyblok/product-detail/StoryblokProductVariant";
import StoryblokStepperAddToCart from "../components/storyblok/product-detail/StoryblokStepperAddToCart";
import StoryblokProductList from "../components/storyblok/StoryblokProductList";
import StoryblokProduct from "../components/storyblok/StoryblokProduct";
import StoryblokAccountAddress from "../components/storyblok/user-area/StoryblokAccountAddress";
import StoryblokAccountOrder from "../components/storyblok/user-area/StoryblokAccountOrder";
import StoryblokEmptyCartContent from "../components/storyblok/cart/StoryblokEmptyCartContent";
import StoryblokCartSummary from "../components/storyblok/cart/StoryblokCartSummary";
import StoryblokCartContentContainer from "../components/storyblok/cart/StoryblokCartContentContainer";
import StoryblokCartItemsContainer from "../components/storyblok/cart/StoryblokCartItemsContainer";
import StoryblokNotFoundPage from "../components/storyblok/not-found-page/StoryblokNotFoundPage";
import StoryblokNotFoundPageContent from "../components/storyblok/not-found-page/StoryblokNotFoundPageContent";
import StoryblokCheckoutPage from "../components/storyblok/checkout/StoryblokCheckoutPage";
import StoryblokCheckoutOrderSummary from "../components/storyblok/checkout/StoryblokCheckoutOrderSummary";
import StoryblokCPOuterFlexContainer from "../components/storyblok/checkout/StoryblokCPOuterFlexContainer";
import StoryblokOrderFormContainer from "../components/storyblok/checkout/StoryblokCPInnerLeftFlexContainer";
import StoryblokOrderSummaryContainer from "../components/storyblok/checkout/StoryblokCPInnerRightFlexContainer";
import StoryblokCheckoutSteps from "../components/storyblok/checkout/StoryblokCheckoutSteps";
import StoryblokCheckoutStepContent from "../components/storyblok/checkout/StoryblokCheckoutStepContent";
import StoryblokCartComponent from "../components/storyblok/cart/StoryblokCartComponent";

const storyblokComponents = () => {
  return {
    teaser: StoryblokTeaser,
    page: StoryblokPage,
    banner: StoryblokBanner,
    column: StoryblokColumn,
    grid: StoryblokGrid,
    productDetailPage: StoryblokProductDetailPage,
    innerFlexContainer: StoryblokInnerFlexContainer,
    outerFlexContainer: StoryblokOuterFlexContainer,
    productDescription: StoryblokProductDescription,
    productImage: StoryblokProductImage,
    productName: StoryblokProductName,
    productPrice: StoryblokProductPrice,
    productVariant: StoryblokProductVariant,
    stepperAddToCart: StoryblokStepperAddToCart,
    productList: StoryblokProductList,
    product: StoryblokProduct,
    notFoundPage: StoryblokNotFoundPage,
    notFoundPageContent: StoryblokNotFoundPageContent,
    cartComponent: StoryblokCartComponent,
    emptyCartContent: StoryblokEmptyCartContent,
    cartContentContainer: StoryblokCartContentContainer,
    cartItemsContainer: StoryblokCartItemsContainer,
    cartSummary: StoryblokCartSummary,
    checkoutPage: StoryblokCheckoutPage,
    address: StoryblokAccountAddress,
    orders: StoryblokAccountOrder,
    checkoutOrderSummary: StoryblokCheckoutOrderSummary,
    cpOuterFlexContainer: StoryblokCPOuterFlexContainer,
    orderFormContainer: StoryblokOrderFormContainer,
    orderSummaryContainer: StoryblokOrderSummaryContainer,
    checkoutSteps: StoryblokCheckoutSteps,
    checkoutStepContent: StoryblokCheckoutStepContent
  };
};

export default storyblokComponents
