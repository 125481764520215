import { FC } from "react";
import { useTranslation } from "next-i18next";
import { OrderItem as OrderItemGql } from "@precomposer/data";
import ProductPrice from "../product/ProductPrice";
import Link from "next/link";

type Props = {
  orderItem: OrderItemGql;
};

const AccountOrderItem: FC<Props> = ({ orderItem }) => {
  const { t } = useTranslation(["cart"]);

  return (
    <div className={"grid grid-cols-2 pb-1"}>
      <div>
        <Link href={`/p/${orderItem.productSlug}#sku=${orderItem.sku}`}>
          {orderItem.productName}
        </Link>
      </div>
      <div className={"text-right"}>
        <div>
          {t("cart:items.qty")}: {orderItem.quantity}
        </div>
        <div>
          {t("cart:items.price")}:{" "}
          <ProductPrice centAmount={orderItem.priceInCent} />
        </div>
      </div>
    </div>
  );
};

export default AccountOrderItem;
