import * as Types from "../types.generated";

import { gql } from "@apollo/client";
export type CustomerAddressFragment = {
  key?: string | null;
  type?: string | null;
  streetName?: string | null;
  salutation?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  streetNumber?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
  company?: string | null;
};

export const CustomerAddressFragmentDoc = gql`
  fragment CustomerAddress on CustomerAddress {
    key
    type
    streetName
    salutation
    firstName
    lastName
    streetName
    streetNumber
    postalCode
    city
    country
    company
  }
`;
