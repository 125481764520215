import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { OrderListFragmentDoc } from "./OrderList.fragment.generated";
import * as Apollo from "@apollo/client";
export type GetOrderListQueryVariables = Types.Exact<{
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetOrderListQuery = {
  myOrders?: {
    orders: Array<{
      orderNumber: string;
      createdAt: string;
      totalPriceInCent: number;
      currencyCode?: string | null;
      orderState: string;
      orderItems: Array<{
        productId: string;
        productName: string;
        productSlug: string;
        sku: string;
        totalPriceInCent: number;
        priceInCent: number;
        quantity: number;
      }>;
    } | null>;
    metadata: { limit: number; count: number; offset: number; total: number };
  } | null;
};

export const GetOrderListDocument = gql`
  query GetOrderList($locale: String) {
    myOrders(locale: $locale) {
      ...OrderList
    }
  }
  ${OrderListFragmentDoc}
`;
export type GetOrderListQueryResult = Apollo.QueryResult<
  GetOrderListQuery,
  GetOrderListQueryVariables
>;
