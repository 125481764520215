import {
  FilterAttribute,
  Image,
  Maybe,
  ProductVariant,
} from "@precomposer/data";
import isString from "lodash/isString";
import { ParsedUrlQuery } from "querystring";
import config from "./config";

export function firstImageUrl(images?: Maybe<Array<Image>>): string {
  if (images && images.length > 0 && images[0].url) {
    return images[0].url;
  }
  return config.placeholderImg;
}

/**
 * Returns the formatted price by given types (locale and currency)
 * @param amount
 * @param currencyCode
 * @param locale
 */
export function formatPrice({
  amount,
  currencyCode,
  locale,
}: {
  amount: number;
  currencyCode: string;
  locale: string;
}): string {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  });

  return formatCurrency.format(amount / 100);
}

/**
 * It is an interim solution to determine any price from a given list.
 * @todo Change price information https://ecube.atlassian.net/browse/CTPT-265
 * @param variant
 */
export function getVariantPrice({
  variant,
}: {
  variant: ProductVariant;
}): number {
  let prices = variant.prices.map((price) => price.centAmount);

  if (prices) {
    return Math.min(...prices);
  }

  return 0;
}

/**
 * Returns the lowest formatted price of all product variations, i.e. an "Price from ..."
 * @param variants
 */
export function getMinVariantsPrice({
  variants,
}: {
  variants: ProductVariant[] | null;
}): number {
  let prices = variants
    ?.map((variant) => variant.prices?.map((price) => price.centAmount))
    .flat();

  if (prices) {
    return Math.min(...prices);
  }

  return 0;
}

/**
 * Returns the lowest formatted discount of all product variations (if there is one)
 * @param variants
 */
export function getMinVariantsDiscount({
  variants,
}: {
  variants: ProductVariant[] | null;
}): number | null {
  let discounts = variants
    ?.map((variant) =>
      variant.prices?.map((price) => price.discountedCentAmount)
    )
    .flat();

  if (discounts) {
    let filteredDiscounts: number[] = discounts.filter(
      (discount) => typeof discount === "number"
    ) as number[];
    return Math.min(...filteredDiscounts);
  }

  return null;
}

/**
 * Returns the taxType of the product variant
 * @param variant
 */
export function getVariantTaxType({
  variant,
}: {
  variant: ProductVariant;
}): string {
  let minPrice = variant.prices.reduce((prev, current) =>
    prev.centAmount < current.centAmount ? prev : current
  );
  return minPrice.taxType;
}

/**
 * Returns the discounted price in cent of the product variant
 * @param variant
 */
export function getVariantDiscount({
  variant,
}: {
  variant: ProductVariant;
}): Maybe<number> | undefined {
  let minPrice = variant.prices.reduce((prev, current) =>
    prev.centAmount < current.centAmount ? prev : current
  );
  return minPrice.discountedCentAmount;
}

/**
 * Returns an error message string from given Error object
 * @param error
 */
export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

/**
 * Returns filtered Array with facet filter attributes from given URL query param
 * @param param
 */
export function matchFilterQueryParam(
  param: ParsedUrlQuery
): FilterAttribute[] {
  return Object.entries(param).reduce(
    (accumulator: FilterAttribute[], currentValue) => {
      const match = currentValue[0].match(/filter\[(.*)]/);
      const name = match ? match[1] : undefined;
      const values = currentValue[1];
      if (values && name) {
        return [
          ...accumulator,
          {
            name: name,
            values: isString(values) ? [values] : values,
          },
        ];
      }

      return accumulator;
    },
    []
  );
}
