import React, { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const Grid: FC<Props> = ({ children }) => (
  <div className={"mb-4 grid gap-4 md:grid-cols-12"}>{children}</div>
);

export default Grid;
