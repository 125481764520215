import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { ImageFragmentDoc } from "../common/Image.fragment.generated";
export type CartItemFragment = {
  id: string;
  quantity: number;
  productId: string;
  productName: string;
  productSlug: string;
  sku: string;
  priceInCent: number;
  totalNetPriceInCent: number;
  totalGrossPriceInCent: number;
  images?: Array<{
    alt: string;
    height: string;
    title?: string | null;
    url: string;
    width: string;
  }> | null;
};

export const CartItemFragmentDoc = gql`
  fragment CartItem on CartItem {
    id
    quantity
    productId
    productName
    productSlug
    sku
    priceInCent
    totalNetPriceInCent
    totalGrossPriceInCent
    images {
      ...Image
    }
  }
  ${ImageFragmentDoc}
`;
