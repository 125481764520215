import React, { FC, ReactNode } from "react";
import { useTranslation } from "next-i18next";
import { Breadcrumb } from "@precomposer/ui";
import Meta from "../../layout/Meta";
import NotificationBanner from "../NotificationBanner";
import { Product } from "@precomposer/data";

type Props = {
  children: ReactNode;
  product: Product;
  addToCartSuccess: boolean;
  onNotificationBannerClose: any;
};

const calculateTimer = (text: string): number => {
  const wordCount = text?.split(" ").length || 0;
  return Math.ceil(wordCount / 4) * 1000;
};

const ProductDetailPageComponent: FC<Props> = ({
  product,
  children,
  addToCartSuccess,
  onNotificationBannerClose,
}) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <Meta title={product.name} description={product.description} />
      <Breadcrumb>
        <Breadcrumb.Item href={"/"}>{t("common:home.title")}</Breadcrumb.Item>
        <Breadcrumb.Item>{product.name}</Breadcrumb.Item>
      </Breadcrumb>
      {addToCartSuccess && (
        <div className="absolute right-0 top-0">
          <NotificationBanner
            onClose={onNotificationBannerClose}
            isPermanent={false}
            timer={calculateTimer(t("product:notification-message"))}
            bgColor={"bg-cyan-300"}
          >
            {t("product:notification-message")}
          </NotificationBanner>
        </div>
      )}
      <div>{children}</div>
    </>
  );
};

export default ProductDetailPageComponent;
