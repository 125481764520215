import { storyblokEditable } from "@storyblok/react";
import React from "react";
import Banner from "../../cms/Banner";

const StoryblokBanner = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <Banner image={blok.image} link={blok.link} />
    </div>
  );
};

export default StoryblokBanner;
