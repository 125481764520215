import React, { FC, ReactNode } from "react";
import classNames from "classnames";

type Props = {
  children: ReactNode;
  colspan: string;
};

const Column: FC<Props> = ({ colspan, children }) => (
  <div className={classNames(`lg:col-span-${colspan}`, "col-span-12")}>
    {children}
  </div>
);

export default Column;
