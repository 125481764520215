import ProductCardCMS from "../cms/ProductCardCMS";
import { storyblokEditable } from "@storyblok/react";

const StoryblokProduct = ({ blok, product, index }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <ProductCardCMS
        product={product}
        index={index}
        frameColor={blok.frame_color}
        btnColor={blok.order_btn_color}
        hasShadow={blok.hasShadow}
        borderRadius={blok.borderRadius}
        float={blok.float}
      />
    </div>
  );
};

export default StoryblokProduct;
