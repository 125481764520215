import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import { AttributeDefinitionFragmentDoc } from "../product/AttributeDefinition.fragment.generated";
import { ProductVariantFragmentDoc } from "../product/ProductVariant.fragment.generated";
import { ImageFragmentDoc } from "../../common/Image.fragment.generated";
export type ProductFragment = {
  id: string;
  slug?: string | null;
  name?: string | null;
  description?: string | null;
  attributeDefinitions?: Array<{
    attributeConstraint: string;
    label?: string | null;
    name: string;
    type: any;
  }> | null;
  variants: Array<{
    id: string;
    sku: string;
    isMaster: boolean;
    isMatching: boolean;
    attributesRaw: Array<{ name: string; value: any }>;
    prices: Array<{
      centAmount: number;
      discountedCentAmount?: number | null;
      taxType: string;
    }>;
  }>;
  images?: Array<{
    alt: string;
    height: string;
    title?: string | null;
    url: string;
    width: string;
  }> | null;
};

export const ProductFragmentDoc = gql`
  fragment Product on Product {
    id
    slug
    name
    description
    attributeDefinitions {
      ...AttributeDefinition
    }
    variants {
      ...ProductVariant
    }
    images {
      ...Image
    }
  }
  ${AttributeDefinitionFragmentDoc}
  ${ProductVariantFragmentDoc}
  ${ImageFragmentDoc}
`;
