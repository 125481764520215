import React from "react";
import * as Yup from "yup";
import { useSession } from "next-auth/react";
import { useFormikContext } from "formik";
import { useTranslation } from "next-i18next";
import { SetCustomerEmailAction } from "@precomposer/data";
import { InputText } from "@precomposer/ui";

export const checkoutEmailAddressSchema = Yup.object().shape({
  email: Yup.string()
    .email("checkout:addresses.form.email.msg")
    .required("checkout:addresses.form.email.error"),
});

export const checkoutEmailAddressFields: SetCustomerEmailAction = {
  email: "",
};

export const CheckoutEmailAddressFieldset = () => {
  const { t } = useTranslation(["checkout"]);
  const { data: session } = useSession();
  const { values, touched, errors, handleChange } =
    useFormikContext<typeof checkoutEmailAddressFields>();

  return (
    <>
      {!session && (
        <fieldset className={"mb-4 grid gap-4 md:grid-cols-12"}>
          <legend className={"pb-3"}>
            {t("checkout:addresses.form.email.legend")}
          </legend>
          <div className={"col-span-12"}>
            <InputText
              type={"email"}
              id={"email"}
              name={"email"}
              placeholder={"email@example.com"}
              label={t("checkout:addresses.form.email.label")}
              fullWidth
              value={values.email}
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              message={
                touched.email &&
                errors.email !== undefined &&
                t(`${errors.email}` as unknown as TemplateStringsArray)
              }
            />
          </div>
        </fieldset>
      )}
    </>
  );
};
