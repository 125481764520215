import React, { Dispatch, FC, SetStateAction } from "react";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useTranslation } from "next-i18next";
import { useCart, usePayment } from "@precomposer/data";
import { Button, Checkbox } from "@precomposer/ui";

type Props = {
  setStep: Dispatch<SetStateAction<number>>;
};

const checkoutConfirmationFormSchema = Yup.object().shape({
  acceptTerms: Yup.bool().oneOf(
    [true],
    "checkout:confirmation.form.terms.error"
  ),
});

const checkoutConfirmationFormFields = {
  acceptTerms: false,
};

const CheckoutConfirmationForm: FC<Props> = ({ setStep }) => {
  const { t } = useTranslation(["checkout"]);
  const { placeOrderAction } = useCart();
  const { paymentMethod } = usePayment();
  const { errors, values, touched, handleSubmit, handleChange, isSubmitting } =
    useFormik({
      initialValues: checkoutConfirmationFormFields,
      validationSchema: checkoutConfirmationFormSchema,
      onSubmit: submitForm,
    });

  async function submitForm(
    values: typeof checkoutConfirmationFormFields,
    {
      resetForm,
      setSubmitting,
    }: FormikHelpers<typeof checkoutConfirmationFormFields>
  ) {
    await placeOrderAction();
    setSubmitting(false);
    resetForm({ values: checkoutConfirmationFormFields });
    setStep(4);
  }

  return (
    <form onSubmit={handleSubmit} className={"py-4"}>
      <fieldset className={"mb-4"}>
        <legend className={"pb-3"}>
          {t("checkout:confirmation.form.legend")}
        </legend>
        <div className={"mb-6"}>
          <Checkbox
            id={"accept-terms"}
            name={"acceptTerms"}
            label={t("checkout:confirmation.form.terms.label")}
            checked={values.acceptTerms}
            onChange={handleChange}
            error={touched.acceptTerms && Boolean(errors.acceptTerms)}
            message={
              touched.acceptTerms &&
              errors.acceptTerms !== undefined &&
              t(`${errors.acceptTerms}` as unknown as TemplateStringsArray)
            }
          />
        </div>
        <Button
          type={"submit"}
          size={"large"}
          variant={"primary"}
          disabled={isSubmitting || !paymentMethod}
        >
          {t("checkout:confirmation.form.submit")}
        </Button>
      </fieldset>
    </form>
  );
};

export default CheckoutConfirmationForm;
