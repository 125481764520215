import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
export type FacetFragment = {
  name: string;
  type: string;
  dataType: string;
  label?: string | null;
  values?: Array<
    | {
        from: number;
        fromStr?: string | null;
        to?: number | null;
        toStr?: string | null;
        count: number;
        productCount?: number | null;
        total: number;
        min: number;
        max: number;
        mean: number;
      }
    | { term: string; count: number; label?: string | null }
  > | null;
  displayOptions?: Array<{ key: string; value: string }> | null;
};

export const FacetFragmentDoc = gql`
  fragment Facet on Facet {
    name
    type
    dataType
    label
    values {
      ... on FacetRange {
        from
        fromStr
        to
        toStr
        count
        productCount
        total
        min
        max
        mean
      }
      ... on FacetTerm {
        term
        count
        label
      }
    }
    displayOptions {
      key
      value
    }
  }
`;
