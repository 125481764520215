import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type GetCategorySitemapQueryVariables = Types.Exact<{
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetCategorySitemapQuery = {
  categoryList?: { result?: Array<{ slug: string } | null> | null } | null;
};

export const GetCategorySitemapDocument = gql`
  query GetCategorySitemap($locale: String) {
    categoryList(locale: $locale) {
      result {
        slug
      }
    }
  }
`;
export type GetCategorySitemapQueryResult = Apollo.QueryResult<
  GetCategorySitemapQuery,
  GetCategorySitemapQueryVariables
>;
