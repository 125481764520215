import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type GetCmsSitemapQueryVariables = Types.Exact<{
  slugs?: Types.InputMaybe<Types.Scalars["String"]>;
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetCmsSitemapQuery = {
  pageMetadata?: Array<{ slug: string } | null> | null;
};

export const GetCmsSitemapDocument = gql`
  query GetCmsSitemap($slugs: String, $locale: String) {
    pageMetadata(slugs: $slugs, locale: $locale) {
      slug
    }
  }
`;
export type GetCmsSitemapQueryResult = Apollo.QueryResult<
  GetCmsSitemapQuery,
  GetCmsSitemapQueryVariables
>;
