import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import React from "react";
import { Product, ProductVariant } from "@precomposer/data";

const StoryblokInnerFlexContainer = ({ blok, ...restProps }) => {
  const product: Product = restProps.product;
  const variant: ProductVariant = restProps.variant;
  const qty: number = restProps.qty;
  const setQty: React.Dispatch<React.SetStateAction<number>> = restProps.setQty;
  const addToCart: any = restProps.addToCart;

  return (
    <div className="flex flex-col space-y-3" {...storyblokEditable(blok)}>
      {blok.children ? (
        blok.children.map((nestedBlok) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            product={product}
            variant={variant}
            qty={qty}
            setQty={setQty}
            addToCart={addToCart}
          />
        ))
      ) : (
        <p>NO CHILDREN</p>
      )}
    </div>
  );
};

export default StoryblokInnerFlexContainer;
