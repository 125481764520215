import React, { createContext } from "react";
import { defaultUseCart, UseCart } from "./UseCart";
import { defaultUseCustomer, UseCustomer } from "./UseCustomer";
import { defaultUsePayment, UsePayment } from "./UsePayment";

export type DataContextType = {
  cart: UseCart;
  customer: UseCustomer;
  payment: UsePayment;
};

export const defaultContext: DataContextType = {
  cart: defaultUseCart,
  customer: defaultUseCustomer,
  payment: defaultUsePayment,
};

export const DataContext = createContext<DataContextType>(defaultContext);
