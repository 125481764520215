import { storyblokEditable } from "@storyblok/react";
import React from "react";
import { Product, ProductVariant } from "@precomposer/data";
import ProductOptions from "../../product/ProductOptions";

const StoryblokProductVariant = ({ blok, ...restProps }) => {
  const variant: ProductVariant = restProps.variant;
  const product: Product = restProps.product;

  return (
    <div {...storyblokEditable(blok)}>
      <ProductOptions
        variants={product.variants}
        variant={variant}
        productSlug={product.slug}
      />
    </div>
  );
};

export default StoryblokProductVariant;
