import { storyblokEditable } from "@storyblok/react";
import React from "react";
import { ProductVariant } from "@precomposer/data";
import {
  getVariantDiscount,
  getVariantPrice,
  getVariantTaxType,
} from "../../../lib/util";
import ProductPrice from "../../product/ProductPrice";

const StoryblokProductPrice = ({ blok, ...restProps }) => {
  const variant: ProductVariant = restProps.variant;

  return (
    <div
      className="flex flex-row items-end space-x-2"
      {...storyblokEditable(blok)}
    >
      <ProductPrice
        centAmount={getVariantPrice({ variant: variant })}
        discountedCentAmount={getVariantDiscount({ variant: variant })}
        taxType={getVariantTaxType({ variant: variant })}
      />
    </div>
  );
};

export default StoryblokProductPrice;
