import { Button } from "@precomposer/ui";

export default function EmptyCartContent({
  info,
  btn_text,
  btn_link,
  btn_variant,
  btn_size,
}) {
  return (
    <>
      <div>
        <h2 className="mb-4">{info}</h2>
        <Button href={btn_link} variant={btn_variant} size={btn_size}>
          {btn_text}
        </Button>
      </div>
    </>
  );
}
