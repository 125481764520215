import * as Types from "../types.generated";

import { gql } from "@apollo/client";
import { CustomerFragmentDoc } from "./Customer.fragment.generated";
import * as Apollo from "@apollo/client";
export type UpdateCustomerMutationVariables = Types.Exact<{
  actions:
    | Array<Types.InputMaybe<Types.CustomerUpdateAction>>
    | Types.InputMaybe<Types.CustomerUpdateAction>;
}>;

export type UpdateCustomerMutation = {
  batchUpdateMyCustomer?: {
    id: string;
    version: number;
    email: string;
    isEmailVerified?: boolean | null;
    token?: string | null;
    defaultBillingAddressKey?: string | null;
    defaultShippingAddressKey?: string | null;
    addresses: Array<{
      key?: string | null;
      type?: string | null;
      streetName?: string | null;
      salutation?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      streetNumber?: string | null;
      postalCode?: string | null;
      city?: string | null;
      country?: string | null;
      company?: string | null;
    }>;
  } | null;
};

export const UpdateCustomerDocument = gql`
  mutation UpdateCustomer($actions: [CustomerUpdateAction]!) {
    batchUpdateMyCustomer(actions: $actions) {
      ...Customer
    }
  }
  ${CustomerFragmentDoc}
`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export type UpdateCustomerMutationResult =
  Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
