import React, { FC } from "react";
import { Product } from "@precomposer/data";
import ProductGallery from "../../product/ProductGallery";

type Props = {
  product: Product;
};

const ProductImage: FC<Props> = ({ product }) => {
  return <ProductGallery images={product.images} />;
};

export default ProductImage;
