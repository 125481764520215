import React from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useGetOrderList } from "@precomposer/data";

import { env } from "@precomposer/env";
import AccountOrderItem from "../../account/AccountOrderItem";
import ProductPrice from "../../product/ProductPrice";

const AccountOrderCMS = ({ orderData, gap, flex, frameColor, hasShadow }) => {
  const { locale } = useRouter();
  const { t } = useTranslation(["orders"]);

  const { data, loading, error } = useGetOrderList({
    variables: {
      locale: locale || env("DEFAULT_LOCALE"),
    },
  });

  orderData = data?.myOrders || orderData;

  function formatDate(date: string | number | Date) {
    return new Date(date).toLocaleDateString(locale || env("DEFAULT_LOCALE"));
  }

  return (
    <div style={{ display: "flex", flexDirection: flex, gap: `${gap}px` }}>
      {orderData &&
        orderData.orders?.map(
          (
            order: {
              orderItems: any[];
              totalPriceInCent: number;
              orderState:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | Iterable<React.ReactNode>
                | React.ReactPortal
                | React.PromiseLikeOfReactNode;
              createdAt: string | number | Date;
            },
            index: React.Key
          ) => (
            <div
              key={index}
              style={{
                width: flex === "row" ? "25%" : null,
                border: frameColor !== "" ? `1px solid ${frameColor}` : null,
                boxShadow: hasShadow == "true" ? `0 4px 8px ${frameColor}` : "",
                overflow: "hidden",
                borderRadius: "8px",
              }}
            >
              <div
                className={`grid ${
                  frameColor === "" ? "border" : ""
                } rounded-lg p-3 md:grid-cols-2`}
              >
                <div>
                  {order.orderItems?.map((orderItem) => (
                    <AccountOrderItem
                      orderItem={orderItem}
                      key={orderItem.productId}
                    ></AccountOrderItem>
                  ))}
                </div>
                <div className={`pt-2 text-right md:border-none`}>
                  <div className={""}>
                    {t("orders:totalPrice")}:{" "}
                    <ProductPrice centAmount={order.totalPriceInCent} />
                  </div>
                  <div className={"font-bold"}>
                    {t("orders:orderState")}: {order.orderState}
                  </div>
                  <div className={"italic"}>
                    {t("orders:date")}: {formatDate(order.createdAt)}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
    </div>
  );
};

export default AccountOrderCMS;
