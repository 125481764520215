import { storyblokEditable } from "@storyblok/react";
import React from "react";
import Teaser from "../../cms/Teaser";

const StoryblokTeaser = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <Teaser
        text={blok.text}
        image={blok.image}
        headline={blok.headline}
        subheadline={blok.subheadline}
      />
    </div>
  );
};

export default StoryblokTeaser;
