import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
import { CategoryFragmentDoc } from "./Category.fragment.generated";
import * as Apollo from "@apollo/client";
export type GetCategoryQueryVariables = Types.Exact<{
  categorySlug: Types.Scalars["String"];
  locale?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetCategoryQuery = {
  categoryBySlug?: {
    id: string;
    key?: string | null;
    name: string;
    slug: string;
    description?: string | null;
  } | null;
};

export const GetCategoryDocument = gql`
  query GetCategory($categorySlug: String!, $locale: String) {
    categoryBySlug(slug: $categorySlug, locale: $locale) {
      ...Category
    }
  }
  ${CategoryFragmentDoc}
`;
export type GetCategoryQueryResult = Apollo.QueryResult<
  GetCategoryQuery,
  GetCategoryQueryVariables
>;
