import React, {
  Context,
  HTMLAttributes,
  useContext,
  useEffect,
  useState,
} from "react";
import { DataContextType } from "./DataContext";
import {
  AddLineItemAction,
  Cart,
  CartUpdateAction,
  ChangeLineItemQuantityAction,
  Customer,
  CustomerUpdateAction,
  RemoveLineItemAction,
  SetBillingAddressAction,
  SetCustomerEmailAction,
  SetShippingAddressAction,
  SignUpCustomerData,
  UpdateAddressAction,
} from "../graphql/types.generated";
import { useSession } from "next-auth/react";
import isString from "lodash/isString";
import { cookie } from "../lib/cookie";
import { useUpdateCart } from "../hooks/useUpdateCart";
import { useGetCart } from "../hooks/useGetCart";
import { useSignUpCustomer } from "../hooks/useSignUpCustomer";
import { useGetCustomer } from "../hooks/useGetCustomer";
import { useUpdateCustomer } from "../hooks/useUpdateCustomer";

type Props = {
  context: Context<DataContextType>;
} & HTMLAttributes<HTMLElement>;

const DataProvider = ({ context, children }: Props) => {
  const { getCookie, deleteCookie } = cookie({ key: "cartId" });
  const { data: session } = useSession();
  const { updateCart } = useUpdateCart();
  const { getCart } = useGetCart();
  const { signUpCustomer } = useSignUpCustomer();
  const { getCustomer } = useGetCustomer();
  const { updateCustomer } = useUpdateCustomer();
  const data = useContext(context);
  const [cart, setCart] = useState<Cart>(data.cart.cartData);
  const [payment, setPayment] = useState<string | undefined>();
  const [customer, setCustomer] = useState<Customer>(
    data.customer.customerData
  );

  useEffect(() => {
    const cartId = getCookie();
    if (session || (isString(cartId) && cartId !== "")) {
      getCart().then((cart) => {
        setCart(cart);
      });
    }
    if (session) {
      getCustomer().then((customer) => {
        if (customer) setCustomer(customer);
      });
    }
  }, [session, getCart, getCookie, getCustomer]);

  const addLineItemAction = async ({
    sku,
    quantity = 1,
  }: AddLineItemAction): Promise<boolean> => {
    if (!quantity || quantity <= 0) return false;
    const action: Array<CartUpdateAction> = new Array<CartUpdateAction>({
      addLineItemAction: { sku, quantity },
    });
    const cart = await updateCart(action);
    if (cart) {
      setCart(cart);
      return true;
    }
    return false;
  };

  const removeLineItemAction = async ({
    lineItemId,
  }: RemoveLineItemAction): Promise<boolean> => {
    const action: Array<CartUpdateAction> = new Array<CartUpdateAction>({
      removeLineItemAction: { lineItemId },
    });
    const cart = await updateCart(action);
    if (cart) {
      setCart(cart);
      return true;
    }
    return false;
  };

  const changeLineItemQuantityAction = async ({
    lineItemId,
    quantity,
  }: ChangeLineItemQuantityAction): Promise<boolean> => {
    if (!quantity || quantity < 0) return false;
    const action: Array<CartUpdateAction> = new Array<CartUpdateAction>({
      changeLineItemQuantityAction: { lineItemId, quantity },
    });
    const cart = await updateCart(action);
    if (cart) {
      setCart(cart);
      return true;
    }
    return false;
  };

  const setCartAddressAction = async ({
    setBillingAddressAction,
    setShippingAddressAction,
    setCustomerEmailAction = null,
  }: {
    setBillingAddressAction: SetBillingAddressAction;
    setShippingAddressAction: SetShippingAddressAction;
    setCustomerEmailAction?: SetCustomerEmailAction | null;
  }): Promise<boolean> => {
    if (!setBillingAddressAction || !setShippingAddressAction) return false;
    const action: Array<CartUpdateAction> = new Array<CartUpdateAction>(
      { setBillingAddressAction },
      { setShippingAddressAction }
    );
    if (setCustomerEmailAction) {
      const extendedAction = new Array<CartUpdateAction>({
        setCustomerEmailAction,
      });
      Array.prototype.push.apply(action, extendedAction);
    }
    const cart = await updateCart(action);
    if (cart) {
      setCart(cart);
      return true;
    }
    return false;
  };

  const setPaymentMethodAction = (paymentMethod: string | undefined): void => {
    // todo: Handle all actions to pay the order here
    setPayment(paymentMethod);
  };

  const placeOrderAction = async (): Promise<boolean> => {
    const action: Array<CartUpdateAction> = new Array<CartUpdateAction>({
      placeOrderAction: { orderState: "Confirmed" },
    });
    const cart = await updateCart(action);
    if (cart) {
      setCart(data.cart.cartData);
      deleteCookie();
      return true;
    }
    return false;
  };

  const setCustomerBillingAddressAction = async ({
    address,
  }: UpdateAddressAction): Promise<boolean> => {
    const action: Array<CustomerUpdateAction> = new Array({
      updateBillingAddressAction: { address },
    });
    const customer = await updateCustomer(action);
    if (customer) {
      setCustomer(customer);
      return true;
    }
    return false;
  };

  const setCustomerShippingAddressAction = async ({
    address,
  }: UpdateAddressAction): Promise<boolean> => {
    const action: Array<CustomerUpdateAction> = new Array({
      updateShippingAddressAction: { address },
    });
    const customer = await updateCustomer(action);
    if (customer) {
      setCustomer(customer);
      return true;
    }
    return false;
  };

  const signUpCustomerAction = async (
    address: SignUpCustomerData
  ): Promise<boolean> => {
    const customer = await signUpCustomer(address);
    if (customer) {
      setCustomer(customer);
      return true;
    }
    return false;
  };

  return (
    <context.Provider
      value={{
        cart: {
          cartData: cart,
          addLineItemAction,
          removeLineItemAction,
          changeLineItemQuantityAction,
          setCartAddressAction,
          placeOrderAction,
        },
        customer: {
          customerData: customer,
          setCustomerBillingAddressAction,
          setCustomerShippingAddressAction,
          signUpCustomerAction,
        },
        payment: {
          paymentMethod: payment,
          setPaymentMethodAction,
        },
      }}
    >
      {children}
    </context.Provider>
  );
};

export default DataProvider;
