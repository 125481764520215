import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import React from "react";

const StoryblokNotFoundPage = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <section>
        <div>
          {blok.children.map((nestedBlok) => {
            return (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default StoryblokNotFoundPage;
