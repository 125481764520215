import * as Types from "../../types.generated";

import { gql } from "@apollo/client";
export type MetadataFragment = {
  limit: number;
  count: number;
  offset: number;
  total: number;
};

export const MetadataFragmentDoc = gql`
  fragment Metadata on Metadata {
    limit
    count
    offset
    total
  }
`;
