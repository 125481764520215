import { storyblokEditable } from "@storyblok/react";
import React from "react";
import CheckoutOrderSummary from "../../checkout/CheckoutOrderSummary";

const StoryblokCheckoutOrderSummary = ({ blok, ...restProps }) => {
  const step: number = restProps.step;
  let orderSummary = null;

  if (step < 1) {
    orderSummary = <div></div>;
  } else {
    orderSummary = <CheckoutOrderSummary />;
  }

  return <div {...storyblokEditable(blok)}>{orderSummary}</div>;
};

export default StoryblokCheckoutOrderSummary;
