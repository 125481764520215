import { storyblokEditable } from "@storyblok/react";
import AccountOrderCMS from "../../cms/user-area/AccountOrderCMS";

const StoryblokAccountOrder = ({ blok, orderData }) => {
  return (
    <div {...storyblokEditable(blok)}>
      <AccountOrderCMS
        orderData={orderData}
        gap={blok.gap}
        flex={blok.flex}
        frameColor={blok.frameColor}
        hasShadow={blok.hasShadow}
      />
    </div>
  );
};

export default StoryblokAccountOrder;
