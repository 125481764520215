import React from "react";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { getAllCounties } from "../../lib/data/countries";
import { useTranslation } from "next-i18next";
import { SetBillingAddressAction } from "@precomposer/data";
import { InputText, Select } from "@precomposer/ui";

export const checkoutBillingAddressSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "checkout:addresses.form.billing.msg.first-name"
    )
    .max(40)
    .required("checkout:addresses.form.billing.error.first-name"),
  lastName: Yup.string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
      "checkout:addresses.form.billing.msg.last-name"
    )
    .max(40)
    .required("checkout:addresses.form.billing.error.last-name"),
  streetName: Yup.string().required(
    "checkout:addresses.form.billing.error.street-name"
  ),
  streetNumber: Yup.number()
    .typeError("checkout:addresses.form.billing.msg.street-number")
    .required("checkout:addresses.form.billing.error.street-number"),
  postalCode: Yup.number()
    .typeError("checkout:addresses.form.billing.msg.postal-code")
    .required("checkout:addresses.form.billing.error.postal-code"),
  city: Yup.string().required("checkout:addresses.form.billing.error.city"),
  country: Yup.string().required(
    "checkout:addresses.form.billing.error.country"
  ),
});

export const checkoutBillingAddressFields: SetBillingAddressAction = {
  billingAddress: {
    firstName: "",
    lastName: "",
    streetName: "",
    streetNumber: "",
    postalCode: "",
    city: "",
    country: "",
  },
};

export const CheckoutBillingAddressFieldset = () => {
  const { t } = useTranslation(["checkout"]);
  const { values, touched, errors, handleChange } =
    useFormikContext<typeof checkoutBillingAddressFields>();

  return (
    <fieldset className={"mb-4 grid gap-4 md:grid-cols-12"}>
      <legend className={"pb-3"}>
        {t("checkout:addresses.form.billing.legend")}
      </legend>
      <div className={"col-span-12 lg:col-span-6"}>
        <InputText
          type={"text"}
          id={"billing-address-first-name"}
          name={"billingAddress[firstName]"}
          label={t("checkout:addresses.form.billing.label.first-name")}
          fullWidth
          value={
            values.billingAddress.firstName == null
              ? ""
              : values.billingAddress.firstName
          }
          onChange={handleChange}
          error={
            touched.billingAddress?.firstName &&
            Boolean(errors.billingAddress?.firstName)
          }
          message={
            touched.billingAddress?.firstName &&
            errors.billingAddress?.firstName !== undefined &&
            t(
              `${errors.billingAddress?.firstName}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12 lg:col-span-6"}>
        <InputText
          type={"text"}
          id={"billing-address-last-name"}
          name={"billingAddress[lastName]"}
          label={t("checkout:addresses.form.billing.label.last-name")}
          fullWidth
          value={
            values.billingAddress.lastName == null
              ? ""
              : values.billingAddress.lastName
          }
          onChange={handleChange}
          error={
            touched.billingAddress?.lastName &&
            Boolean(errors.billingAddress?.lastName)
          }
          message={
            touched.billingAddress?.lastName &&
            errors.billingAddress?.lastName !== undefined &&
            t(
              `${errors.billingAddress?.lastName}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12 lg:col-span-10"}>
        <InputText
          type={"text"}
          id={"billing-address-street-name"}
          name={"billingAddress[streetName]"}
          label={t("checkout:addresses.form.billing.label.street-name")}
          fullWidth
          value={
            values.billingAddress.streetName == null
              ? ""
              : values.billingAddress.streetName
          }
          onChange={handleChange}
          error={
            touched.billingAddress?.streetName &&
            Boolean(errors.billingAddress?.streetName)
          }
          message={
            touched.billingAddress?.streetName &&
            errors.billingAddress?.streetName !== undefined &&
            t(
              `${errors.billingAddress?.streetName}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12 lg:col-span-2"}>
        <InputText
          type={"text"}
          id={"billing-address-street-number"}
          name={"billingAddress[streetNumber]"}
          label={t("checkout:addresses.form.billing.label.street-number")}
          fullWidth
          value={
            values.billingAddress.streetNumber == null
              ? ""
              : values.billingAddress.streetNumber
          }
          onChange={handleChange}
          error={
            touched.billingAddress?.streetNumber &&
            Boolean(errors.billingAddress?.streetNumber)
          }
          message={
            touched.billingAddress?.streetNumber &&
            errors.billingAddress?.streetNumber !== undefined &&
            t(
              `${errors.billingAddress?.streetNumber}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12 lg:col-span-4"}>
        <InputText
          type={"text"}
          id={"billing-address-postal-code"}
          name={"billingAddress[postalCode]"}
          label={t("checkout:addresses.form.billing.label.postal-code")}
          fullWidth
          value={
            values.billingAddress.postalCode == null
              ? ""
              : values.billingAddress.postalCode
          }
          onChange={handleChange}
          error={
            touched.billingAddress?.postalCode &&
            Boolean(errors.billingAddress?.postalCode)
          }
          message={
            touched.billingAddress?.postalCode &&
            errors.billingAddress?.postalCode !== undefined &&
            t(
              `${errors.billingAddress?.postalCode}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12 lg:col-span-8"}>
        <InputText
          type={"text"}
          id={"billing-address-city"}
          name={"billingAddress[city]"}
          label={t("checkout:addresses.form.billing.label.city")}
          fullWidth
          value={
            values.billingAddress.city == null ? "" : values.billingAddress.city
          }
          onChange={handleChange}
          error={
            touched.billingAddress?.city && Boolean(errors.billingAddress?.city)
          }
          message={
            touched.billingAddress?.city &&
            errors.billingAddress?.city !== undefined &&
            t(
              `${errors.billingAddress?.city}` as unknown as TemplateStringsArray
            )
          }
        />
      </div>
      <div className={"col-span-12"}>
        <Select
          id={"billing-address-country"}
          name={"billingAddress[country]"}
          label={t("checkout:addresses.form.billing.label.country")}
          fullWidth
          value={
            values.billingAddress.country == null
              ? ""
              : values.billingAddress.country
          }
          onChange={handleChange}
          error={
            touched.billingAddress?.country &&
            Boolean(errors.billingAddress?.country)
          }
          message={
            touched.billingAddress?.country &&
            errors.billingAddress?.country !== undefined &&
            t(
              `${errors.billingAddress?.country}` as unknown as TemplateStringsArray
            )
          }
        >
          <Select.Option>
            {t("checkout:addresses.form.billing.select")}
          </Select.Option>
          {getAllCounties().map(([key, value]) => {
            return (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </fieldset>
  );
};
