import React, { FC } from "react";
import Image, { ImageProps } from "./Image";
import Link, { LinkProps } from "./Link";

type Props = {
  image: ImageProps;
  link: LinkProps;
};

const Banner: FC<Props> = ({ link, image }) => {
  const { alt, ...imageProps } = image;
  return (
    <div className={"pb-6"}>
      {link.url !== "" ? (
        <Link {...link}>
          <Image alt={alt} {...imageProps} />
        </Link>
      ) : (
        <Image alt={alt} {...imageProps} />
      )}
    </div>
  );
};

export default Banner;
