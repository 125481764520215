import React, { useState } from "react";
import config from "../../lib/config";
import { Image as ImageType, Maybe } from "@precomposer/data";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper";
import { FreeMode, Scrollbar, Thumbs } from "swiper/modules";

type Props = {
  images?: Maybe<Array<ImageType>>;
};

const ProductGallery = ({ images }: Props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);

  if (!images) {
    return (
      <Image
        src={config.placeholderImg}
        alt={"Missing product image"}
        width={500}
        height={300}
        priority
      />
    );
  }

  if (images && images?.length === 1) {
    return (
      <Image
        src={images[0].url}
        alt={"Product image 1"}
        width={500}
        height={300}
      />
    );
  }

  return (
    <>
      <Swiper
        className="max-md:hidden md:h-2/3 md:max-h-screen"
        onSwiper={setThumbsSwiper}
        slidesPerView={4.3}
        direction="vertical"
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs]}
      >
        {images?.map((image, index) => (
          <SwiperSlide key={index} className="opacity-40">
            <Image src={image.url} alt={""} width={50} height={150} />
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        className="md:w-3/4"
        spaceBetween={10}
        scrollbar={{
          hide: false,
        }}
        modules={[FreeMode, Thumbs, Scrollbar]}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        breakpoints={{
          0: {
            scrollbar: {
              enabled: true,
            },
          },
          768: {
            scrollbar: {
              enabled: false,
            },
          },
        }}
      >
        {images?.map((image, index) => (
          <SwiperSlide key={index} className="block justify-center text-center">
            <Image
              src={image.url}
              alt={"Product image " + index}
              width={500}
              height={300}
              priority={index === 0}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ProductGallery;
