import React, { FC, Fragment, ReactElement, ReactNode } from "react";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import { SessionProvider, useSession } from "next-auth/react";
import type { Session } from "next-auth";
import { appWithTranslation } from "next-i18next";
import PrecomposerData from "@precomposer/data";
import ErrorBoundary from "../components/layout/ErrorBoundary";

//Storyblok
import { storyblokInit, apiPlugin } from "@storyblok/react";
import storyblokComponents from "../lib/storyblokComponents";

// Import global styles (tailwind)
import "../assets/styles/globals.css";
import { env } from "@precomposer/env";

storyblokInit({
  accessToken: env("STORYBLOK_PREVIEW_TOKEN"),
  use: [apiPlugin],
  components: storyblokComponents(),
});

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<{ session: Session }> & {
  Component: NextPageWithLayout;
};

type Props = {
  children: ReactNode | ReactElement;
};

const AppComponent: FC<Props> = ({ children }) => {
  const { data: session } = useSession();

  return (
    <ErrorBoundary>
      <PrecomposerData token={session?.user.token}>{children}</PrecomposerData>
    </ErrorBoundary>
  );
};

const App = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) => {
  const router = useRouter();
  const getLayout = Component.getLayout ?? ((page) => page);
  const layout = getLayout(<Component {...pageProps} />);

  return (
    <SessionProvider session={session}>
      <Script src="/__ENV.js" strategy={"beforeInteractive"} />
      <AppComponent>
        <Fragment key={router.asPath}>{layout}</Fragment>
      </AppComponent>
    </SessionProvider>
  );
};

export default appWithTranslation(App);
