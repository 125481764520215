import { storyblokEditable } from "@storyblok/react";
import React from "react";
import { cn } from "../../../lib/tailwind-merge.util";
import NotFoundPageContent from "../../cms/not-found-page/NotFoundPageContent";

const StoryblokNotFoundPageContent = ({ blok }) => {
  return (
    <div
      className={cn("flex", "justify-center", "p-6", {
        "flex-col": blok.direction === "img-text",
        "flex-col-reverse": blok.direction === "text-img",
        "items-center": blok.alignment === "center",
        "items-start": blok.alignment === "left",
        "items-end": blok.alignment === "right",
      })}
      {...storyblokEditable(blok)}
    >
      <NotFoundPageContent
        image={blok.image}
        fontSize={blok.fontSize}
        fontFamily={blok.fontFamily}
        text={blok.text}
      />
    </div>
  );
};

export default StoryblokNotFoundPageContent;
