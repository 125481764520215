import { storyblokEditable, StoryblokComponent } from "@storyblok/react";
import React from "react";

const StoryblokCPInnerRightFlexContainer = ({ blok, ...restProps }) => {
  const step: number = restProps.step;
  const setStep: React.Dispatch<React.SetStateAction<number>> =
    restProps.setStep;

  return (
    <div className={"lg:w-1/5"} {...storyblokEditable(blok)}>
      {blok.children ? (
        blok.children.map((nestedBlok) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            step={step}
            setStep={setStep}
          />
        ))
      ) : (
        <p>NO CHILDREN</p>
      )}
    </div>
  );
};

export default StoryblokCPInnerRightFlexContainer;
