import { storyblokEditable } from "@storyblok/react";
import React from "react";
import StepperAddToCart from "../../cms/product-detail/StepperAddToCart";

const StoryblokStepperAddToCart = ({ blok, ...restProps }) => {
  const qty: number = restProps.qty;
  const setQty: React.Dispatch<React.SetStateAction<number>> = restProps.setQty;
  const addToCart: any = restProps.addToCart;

  return (
    <div className={"flex flex-row space-x-3"} {...storyblokEditable(blok)}>
      <StepperAddToCart qty={qty} setQty={setQty} addToCart={addToCart} />
    </div>
  );
};

export default StoryblokStepperAddToCart;
