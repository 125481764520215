import React from "react";
import { useRouter } from "next/router";
import { formatPrice } from "../../lib/util";
import { Maybe } from "graphql/jsutils/Maybe";
import { env } from "@precomposer/env";

type Props = {
  /**
   * Unformatted price as cent amount
   */
  centAmount: number;
  /**
   * dicscounted price as cent amount
   */
  discountedCentAmount?: Maybe<number>;
  /**
   * tax label (vat in- or excluded)
   */
  taxType?: string;
};

const ProductPrice = ({ centAmount, discountedCentAmount, taxType }: Props) => {
  const { locale } = useRouter();
  const defaultCurrencyCode = env("DEFAULT_CURRENCY");
  const defaultLocale = env("DEFAULT_LOCALE");

  if (discountedCentAmount != null) {
    return (
      <>
        <span className={"text-lg"}>
          {formatPrice({
            amount: discountedCentAmount,
            currencyCode: defaultCurrencyCode,
            locale: locale || defaultLocale,
          })}
          <sup className={"text-slate-500"}> {taxType}</sup>
        </span>
        <span className={"text-slate-400 line-through"}>
          {formatPrice({
            amount: centAmount,
            currencyCode: defaultCurrencyCode,
            locale: locale || defaultLocale,
          })}
        </span>
      </>
    );
  } else {
    return (
      <span>
        {formatPrice({
          amount: centAmount,
          currencyCode: defaultCurrencyCode,
          locale: locale || defaultLocale,
        })}
        <sup className="text-slate-500"> {taxType}</sup>
      </span>
    );
  }
};

export default ProductPrice;
